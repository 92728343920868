export default [
  {
    label: "表情",
    children: [
			{ name: '😀' },
			{ name: '😃' },
			{ name: '😄' },
			{ name: '😁' },
			{ name: '😆' },
			{ name: '😅' },
			{ name: '😂' },
			{ name: '🤣' },
			{ name: '😊' },
			{ name: '😇' },
			{ name: '🙂' },
			{ name: '🙃' },
			{ name: '😉' },
			{ name: '😌' },
			{ name: '😍' },
			{ name: '😘' },
			{ name: '😗' },
			{ name: '😙' },
			{ name: '😚' },
			{ name: '😋' },
			{ name: '😛' },
			{ name: '😝' },
			{ name: '😜' },
			{ name: '🤪' },
			{ name: '🤨' },
			{ name: '🧐' },
			{ name: '🤓' },
			{ name: '😎' },
			{ name: '🤩' },
			{ name: '😏' },
			{ name: '😒' },
			{ name: '😞' },
			{ name: '😔' },
			{ name: '😟' },
			{ name: '😕' },
			{ name: '🙁' },
			{ name: '😣' },
			{ name: '😖' },
			{ name: '😫' },
			{ name: '😩' },
			{ name: '😢' },
			{ name: '😭' },
			{ name: '😤' },
			{ name: '😠' },
			{ name: '😡' },
			{ name: '🤬' },
			{ name: '🤯' },
			{ name: '😳' },
			{ name: '😱' },
			{ name: '😨' },
			{ name: '😰' },
			{ name: '😥' },
			{ name: '😓' },
			{ name: '🤗' },
			{ name: '🤔' },
			{ name: '🤭' },
			{ name: '🤫' },
			{ name: '🤥' },
			{ name: '😶' },
			{ name: '😐' },
			{ name: '😑' },
			{ name: '😬' },
			{ name: '🙄' },
			{ name: '😯' },
			{ name: '😦' },
			{ name: '😧' },
			{ name: '😮' },
			{ name: '😲' },
			{ name: '😴' },
			{ name: '🤤' },
			{ name: '😪' },
			{ name: '😵' },
			{ name: '🤐' },
			{ name: '🤢' },
			{ name: '🤮' },
			{ name: '🤧' },
			{ name: '😷' },
			{ name: '🤒' },
			{ name: '🤕' },
			{ name: '🤑' },
			{ name: '🤠' },
			{ name: '😈' },
			{ name: '👿' },
			{ name: '👹' },
			{ name: '👺' },
			{ name: '🤡' },
			{ name: '💩' },
			{ name: '👻' },
			{ name: '💀' },
			{ name: '👽' },
			{ name: '👾' },
			{ name: '🤖' },
			{ name: '🎃' },
			{ name: '😺' },
			{ name: '😸' },
			{ name: '😹' },
			{ name: '😻' },
			{ name: '😼' },
			{ name: '😽' },
			{ name: '🙀' },
			{ name: '😿' },
			{ name: '😾' },
		]
	},
	{
    label: "手势",
    children: [
			{ name: '👋' },
			{ name: '🤚' },
			{ name: '🖐' },
			{ name: '✋' },
			{ name: '🖖' },
			{ name: '👌' },
			{ name: '🤌' },
			{ name: '🤏' },
			{ name: '🤞' },
			{ name: '🤟' },
			{ name: '🤘' },
			{ name: '🤙' },
			{ name: '👈' },
			{ name: '👉' },
			{ name: '👆' },
			{ name: '🖕' },
			{ name: '👇' },
			{ name: '👍' },
			{ name: '👎' },
			{ name: '✊' },
			{ name: '👊' },
			{ name: '🤛' },
			{ name: '🤜' },
			{ name: '👏' },
			{ name: '🙌' },
			{ name: '👐' },
			{ name: '🤲' },
			{ name: '🤝' },
			{ name: '🙏' },
			{ name: '💅' },
			{ name: '🤳' },
			{ name: '💪' },
			{ name: '👣' },
			{ name: '👂' },
			{ name: '👃' },
			{ name: '🧠' },
			{ name: '👀' },
			{ name: '👁' },
			{ name: '👅' },
			{ name: '👄' },
			{ name: '💋' },
		]
	},
	// {
  //   label: "人物",
  //   children: [
	// 		{ name: '👶' },
	// 		{ name: '👧' },
	// 		{ name: '🧒' },
	// 		{ name: '👦' },
	// 		{ name: '👩' },
	// 		{ name: '🧑' },
	// 		{ name: '👨' },
	// 		{ name: '👩‍🦱' },
	// 		{ name: '🧑‍🦱' },
	// 		{ name: '👨‍🦱' },
	// 		{ name: '👩‍🦰' },
	// 		{ name: '🧑‍🦰' },
	// 		{ name: '👨‍🦰' },
	// 		{ name: '👱‍♀️' },
	// 		{ name: '👱' },
	// 		{ name: '👱‍♂️' },
	// 		{ name: '👩‍🦳' },
	// 		{ name: '🧑‍🦳' },
	// 		{ name: '👨‍🦳' },
	// 		{ name: '👩‍🦲' },
	// 		{ name: '🧑‍🦲' },
	// 		{ name: '👨‍🦲' },
	// 		{ name: '🧔' },
	// 		{ name: '👵' },
	// 		{ name: '🧓' },
	// 		{ name: '👴' },
	// 		{ name: '👲' },
	// 		{ name: '👳‍♀️' },
	// 		{ name: '👳' },
	// 		{ name: '👳‍♂️' },
	// 		{ name: '🧕' },
	// 		{ name: '👮‍♀️' },
	// 		{ name: '👮' },
	// 		{ name: '👮‍♂️' },
	// 		{ name: '👷‍♀️' },
	// 		{ name: '👷' },
	// 		{ name: '👷‍♂️' },
	// 		{ name: '💂‍♀️' },
	// 		{ name: '💂' },
	// 		{ name: '💂‍♂️' },
	// 		{ name: '🕵️‍♀️' },
	// 		{ name: '🕵️' },
	// 		{ name: '🕵️‍♂️' },
	// 		{ name: '👩‍⚕️' },
	// 		{ name: '🧑‍⚕️' },
	// 		{ name: '👨‍⚕️' },
	// 		{ name: '👩‍🌾' },
	// 		{ name: '🧑‍🌾' },
	// 		{ name: '👨‍🌾' },
	// 		{ name: '👩‍🍳' },
	// 		{ name: '🧑‍🍳' },
	// 		{ name: '👨‍🍳' },
	// 		{ name: '👩‍🎓' },
	// 		{ name: '🧑‍🎓' },
	// 		{ name: '👨‍🎓' },
	// 		{ name: '👩‍🎤' },
	// 		{ name: '🧑‍🎤' },
	// 		{ name: '👨‍🎤' },
	// 		{ name: '👩‍🏫' },
	// 		{ name: '🧑‍🏫' },
	// 		{ name: '👨‍🏫' },
	// 		{ name: '👩‍🏭' },
	// 		{ name: '🧑‍🏭' },
	// 		{ name: '👨‍🏭' },
	// 		{ name: '👩‍💻' },
	// 		{ name: '🧑‍💻' },
	// 		{ name: '👨‍💻' },
	// 		{ name: '👩‍💼' },
	// 		{ name: '🧑‍💼' },
	// 		{ name: '👨‍💼' },
	// 		{ name: '👩‍🔧' },
	// 		{ name: '🧑‍🔧' },
	// 		{ name: '👨‍🔧' },
	// 		{ name: '👩‍🔬' },
	// 		{ name: '🧑‍🔬' },
	// 		{ name: '👨‍🔬' },
	// 		{ name: '👩‍🎨' },
	// 		{ name: '🧑‍🎨' },
	// 		{ name: '👨‍🎨' },
	// 		{ name: '👩‍🚒' },
	// 		{ name: '🧑‍🚒' },
	// 		{ name: '👨‍🚒' },
	// 		{ name: '👩‍✈️' },
	// 		{ name: '🧑‍✈️' },
	// 		{ name: '👨‍✈️' },
	// 		{ name: '👩‍🚀' },
	// 		{ name: '🧑‍🚀' },
	// 		{ name: '👨‍🚀' },
	// 		{ name: '👩‍⚖️' },
	// 		{ name: '🧑‍⚖️' },
	// 		{ name: '👨‍⚖️' },
	// 		{ name: '👰‍♀️' },
	// 		{ name: '👰' },
	// 		{ name: '👰‍♂️' },
	// 		{ name: '🤵‍♀️' },
	// 		{ name: '🤵' },
	// 		{ name: '🤵‍♂️' },
	// 		{ name: '👸' },
	// 		{ name: '🤴' },
	// 		{ name: '🥷' },
	// 		{ name: '🦸‍♀️' },
	// 		{ name: '🦸' },
	// 		{ name: '🦸‍♂️' },
	// 		{ name: '🦹‍♀️' },
	// 		{ name: '🦹' },
	// 		{ name: '🦹‍♂️' },
	// 		{ name: '🤶' },
	// 		{ name: '🧑‍🎄' },
	// 		{ name: '🎅' },
	// 		{ name: '🧙‍♀️' },
	// 		{ name: '🧙' },
	// 		{ name: '🧙‍♂️' },
	// 		{ name: '🧝‍♀️' },
	// 		{ name: '🧝' },
	// 		{ name: '🧝‍♂️' },
	// 		{ name: '🧛‍♀️' },
	// 		{ name: '🧛' },
	// 		{ name: '🧛‍♂️' },
	// 		{ name: '🧟‍♀️' },
	// 		{ name: '🧟' },
	// 		{ name: '🧟‍♂️' },
	// 		{ name: '🧞‍♀️' },
	// 		{ name: '🧞' },
	// 		{ name: '🧞‍♂️' },
	// 		{ name: '🧜‍♀️' },
	// 		{ name: '🧜' },
	// 		{ name: '🧜‍♂️' },
	// 		{ name: '🧚‍♀️' },
	// 		{ name: '🧚' },
	// 		{ name: '🧚‍♂️' },
	// 		{ name: '👼' },
	// 		{ name: '🤰' },
	// 		{ name: '🤱' },
	// 		{ name: '👩‍🍼' },
	// 		{ name: '🧑‍🍼' },
	// 		{ name: '👨‍🍼' },
	// 		{ name: '🙇‍♀️' },
	// 		{ name: '🙇' },
	// 		{ name: '🙇‍♂️' },
	// 		{ name: '💁‍♀️' },
	// 		{ name: '💁' },
	// 		{ name: '💁‍♂️' },
	// 		{ name: '🙅‍♀️' },
	// 		{ name: '🙅' },
	// 		{ name: '🙅‍♂️' },
	// 		{ name: '🙆‍♀️' },
	// 		{ name: '🙆' },
	// 		{ name: '🙆‍♂️' },
	// 		{ name: '🙋‍♀️' },
	// 		{ name: '🙋' },
	// 		{ name: '🙋‍♂️' },
	// 		{ name: '🧏‍♀️' },
	// 		{ name: '🧏' },
	// 		{ name: '🧏‍♂️' },
	// 		{ name: '🤦‍♀️' },
	// 		{ name: '🤦' },
	// 		{ name: '🤦‍♂️' },
	// 		{ name: '🤷‍♀️' },
	// 		{ name: '🤷' },
	// 		{ name: '🤷‍♂️' },
	// 		{ name: '🙎‍♀️' },
	// 		{ name: '🙎' },
	// 		{ name: '🙎‍♂️' },
	// 		{ name: '🙍‍♀️' },
	// 		{ name: '🙍' },
	// 		{ name: '🙍‍♂️' },
	// 		{ name: '💇‍♀️' },
	// 		{ name: '💇' },
	// 		{ name: '💇‍♂️' },
	// 		{ name: '💆‍♀️' },
	// 		{ name: '💆' },
	// 		{ name: '💆‍♂️' },
	// 		{ name: '🧖‍♀️' },
	// 		{ name: '🧖' },
	// 		{ name: '🧖‍♂️' },
	// 		{ name: '💅' },
	// 		{ name: '🤳' },
	// 		{ name: '💃' },
	// 		{ name: '🕺' },
	// 		{ name: '👯‍♀️' },
	// 		{ name: '👯' },
	// 		{ name: '👯‍♂️' },
	// 		{ name: '🕴' },
	// 		{ name: '👩‍🦽' },
	// 		{ name: '🧑‍🦽' },
	// 		{ name: '👨‍🦽' },
	// 		{ name: '👩‍🦼' },
	// 		{ name: '🧑‍🦼' },
	// 		{ name: '👨‍🦼' },
	// 		{ name: '🚶‍♀️' },
	// 		{ name: '🚶' },
	// 		{ name: '🚶‍♂️' },
	// 		{ name: '👩‍🦯' },
	// 		{ name: '🧑‍🦯' },
	// 		{ name: '👨‍🦯' },
	// 		{ name: '🧎‍♀️' },
	// 		{ name: '🧎' },
	// 		{ name: '🧎‍♂️' },
	// 		{ name: '🏃‍♀️' },
	// 		{ name: '🏃' },
	// 		{ name: '🏃‍♂️' },
	// 		{ name: '🧍‍♀️' },
	// 		{ name: '🧍' },
	// 		{ name: '🧍‍♂️' },
	// 		{ name: '👭' },
	// 		{ name: '🧑‍🤝‍🧑' },
	// 		{ name: '👬' },
	// 		{ name: '👫' },
	// 		{ name: '👩‍❤️‍👩' },
	// 		{ name: '💑' },
	// 		{ name: '👨‍❤️‍👨' },
	// 		{ name: '👩‍❤️‍👨' },
	// 		{ name: '👩‍❤️‍💋‍👩' },
	// 		{ name: '💏' },
	// 		{ name: '👨‍❤️‍💋‍👨' },
	// 		{ name: '👩‍❤️‍💋‍👨' },
	// 		{ name: '👪' },
	// 		{ name: '👨‍👩‍👦' },
	// 		{ name: '👨‍👩‍👧' },
	// 		{ name: '👨‍👩‍👧‍👦' },
	// 		{ name: '👨‍👩‍👦‍👦' },
	// 		{ name: '👨‍👩‍👧‍👧' },
	// 		{ name: '👨‍👨‍👦' },
	// 		{ name: '👨‍👨‍👧' },
	// 		{ name: '👨‍👨‍👧‍👦' },
	// 		{ name: '👨‍👨‍👦‍👦' },
	// 		{ name: '👨‍👨‍👧‍👧' },
	// 		{ name: '👩‍👩‍👦' },
	// 		{ name: '👩‍👩‍👧' },
	// 		{ name: '👩‍👩‍👧‍👦' },
	// 		{ name: '👩‍👩‍👦‍👦' },
	// 		{ name: '👩‍👩‍👧‍👧' },
	// 		{ name: '👨‍👦' },
	// 		{ name: '👨‍👦‍👦' },
	// 		{ name: '👨‍👧' },
	// 		{ name: '👨‍👧‍👦' },
	// 		{ name: '👨‍👧‍👧' },
	// 		{ name: '👩‍👦' },
	// 		{ name: '👩‍👦‍👦' },
	// 		{ name: '👩‍👧' },
	// 		{ name: '👩‍👧‍👦' },
	// 		{ name: '👩‍👧‍👧' },
	// 		{ name: '🗣' },
	// 		{ name: '👤' },
	// 		{ name: '👥' },
	// 		{ name: '🫂' },
	// 	]
	// },
	// {
  //   label: "",
  //   children: [
	// 		{ name: '😀' },
	// 	]
	// },
]


