
// import { Buffer } from 'buffer'

export function decodeId(id){
  const salt = /[0-9]+/g
  const list = id.match(salt)
  const item = {
    kefuid: Number(list[0]),
    zhuboid: Number(list[1]),
    userid: Number(list[2])
  }
  return item
}

export function encodeId(e){
	return 'c' + e.kefuid + 'h'+ e.zhuboid + 'u' + e.userid
}

export function sTob(str) {
  var utf8 = [];
  for (var i=0; i < str.length; i++) {
      var charcode = str.charCodeAt(i);
      if (charcode < 0x80) utf8.push(charcode);
      else if (charcode < 0x800) {
          utf8.push(0xc0 | (charcode >> 6), 
                    0x80 | (charcode & 0x3f));
      }
      else if (charcode < 0xd800 || charcode >= 0xe000) {
          utf8.push(0xe0 | (charcode >> 12), 
                    0x80 | ((charcode>>6) & 0x3f), 
                    0x80 | (charcode & 0x3f));
      }
      // surrogate pair
      else {
          i++;
          // UTF-16 encodes 0x10000-0x10FFFF by
          // subtracting 0x10000 and splitting the
          // 20 bits of 0x0-0xFFFFF into two halves
          charcode = 0x10000 + (((charcode & 0x3ff)<<10)
                    | (str.charCodeAt(i) & 0x3ff));
          utf8.push(0xf0 | (charcode >>18), 
                    0x80 | ((charcode>>12) & 0x3f), 
                    0x80 | ((charcode>>6) & 0x3f), 
                    0x80 | (charcode & 0x3f));
      }
  }
  return utf8;
}

export function bTos(arr) {
  if (typeof arr === 'string') {
    return arr
  }
  var str = ''
  var _arr = arr
  for (var i = 0; i < _arr.length; i++) {
    var one = _arr[i].toString(2)
    var v = one.match(/^1+?(?=0)/)
    if (v && one.length == 8) {
      var bytesLength = v[0].length
      var store = _arr[i].toString(2).slice(7 - bytesLength)
      for (var st = 1; st < bytesLength; st++) {
        store += _arr[st + i].toString(2).slice(2)
      }
      str += String.fromCharCode(parseInt(store, 2))
      i += bytesLength - 1
    } else {
      str += String.fromCharCode(_arr[i])
    }
  }
  return str
}

export function bToutf8(str) {
  var result = new Array();
  var k = 0;
  for (var i = 0; i < str.length; i++) {
      var j = encodeURI(str[i]);
      if (j.length == 1) {
          // 未转换的字符
          result[k++] = j.charCodeAt(0);
      } else {
          // 转换成%XX形式的字符
          var bytes = j.split("%");
          for (var l = 1; l < bytes.length; l++) {
              result[k++] = parseInt("0x" + bytes[l]);
          }
      }
  }
  return result;
}

export function utf8Tos(arr) {
  let val = ''
  arr.forEach(item => {
      if (item < 127) {
          val += String.fromCharCode(item)
      } else {
          val += '%' + item.toString(16).toUpperCase()
      }
  })
  try {
      return decodeURI(val)
  } catch (err) {
      return val
  }
}