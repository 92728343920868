import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import YumyImui from './plug'

import {
  Alert,
  Button,
  Dialog,
  Drawer,
  Avatar,
  Loading,
  // Message,
  Input,
  Row,
  Col,
  Form,
  Card,
  Tooltip,
  FormItem
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'

Vue.config.productionTip = false

Vue.use(YumyImui)

Vue.use(Alert)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Avatar)
Vue.use(Drawer)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.use(Loading.directive)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
