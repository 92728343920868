// import ContactsData from "./contacts";
// import UserData from "./user";
// const generateRandId = () => {
//   return Math.random()
//     .toString(36)
//     .substr(-8);
// };
// const getContact = id => {
//   const data = ContactsData.find(contact => contact.id == id);
//   return { id: data.id, avatar: data.avatar, displayName: data.displayName };
// };
export default {
};
