import socket from './connect'
// import cmdim from './cmdim'
// import store from '@/store'




function init() {
	socket.init()
}

function restart () {
	// socket.restart()
}


const websocket = {
	init,
	restart
}

export default websocket