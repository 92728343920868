<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

// eslint-disable-next-line no-unused-vars
import socket from '@/socket/main.js'
import store from '@/store';

export default {
  mounted() {
    document.addEventListener('visibilitychange', function() {
      // 用户离开了当前页面
      if (document.visibilityState === 'hidden') {
        console.error('visibilityState===hidden')
      }

      // 用户打开或回到页面
      if (document.visibilityState === 'visible') {
        console.error('Restart loading...')
      }
    })

  },
  created() {
    this.initContasts()
  },
  methods: {
    initContasts(){
      store.dispatch('msg/initConstactsLists')
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
