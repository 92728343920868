// userstatus 0: not login 1: login 2: system logout
const state = {
  isDialog: false,
	loading: false,
}

const mutations = {
  SET_HOSTERIMGSDAILOG: (state, status) => {
    state.isDialog = status
  },
  SET_LOADING: (state, status) => {
    state.loading = status
  }
}

const actions = {
  setOpenState({ commit }) {
    commit('SET_HOSTERIMGSDAILOG', true)
  },
  setCloseState({ commit }) {
    commit('SET_HOSTERIMGSDAILOG', false)
  },

	setLoadingStart({ commit }){
		commit('SET_LOADING', true)
	},

	setLoadingEnd({ commit }){
		commit('SET_LOADING', false)
	},
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
