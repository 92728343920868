const getters = {
  webStatus: state => state.websocket.status,

  userStatus: state => state.login.userStatus,
  clientId: state => state.login.clientId,

  contactsData: state => state.msg.contactsData,
  userLists: state => state.msg.userLists,
  newMsgLists: state => state.msg.newMsgLists,
  revNewMsg: state => state.msg.revNewMsg,
  historyMsg: state => state.msg.hMsg,
  sendMsgStatus: state => state.msg.sendMsgStatus,
  hostImgs: state => state.msg.hostImgs,

  loading: state => state.status.loading,
  isDialog: state => state.status.isDialog,

}
export default getters
