export default {
  id: 1000,
  uid: 1000,
  avatar: "https://p.qqan.com/up/2018-4/15244505348390471.jpg",
  displayName: "野火。",
  country: 'IN',
  language: 'en',
  birthday: '111111111', 
  gender: 1
};
