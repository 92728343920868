<script>
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { useScopedSlot, clearHtmlExcludeImg } from "@/utils";
const exec = (val, command = "insertHTML") => {
  document.execCommand(command, false, val);
};
const selection = window.getSelection();
let lastSelectionRange;
let emojiData = [];
// eslint-disable-next-line no-unused-vars
let isInitTool = false;
export default {
  name: "YumyEditor",
  inject: {
    IMUI: {
      from: "IMUI",
      default() {
        return this;
      },
    },
  },
  components: {},
  props: {
    tools: {
      type: Array,
      default: () => [],
    },
    zhuboInfo: {
      type: Object,
      default: () => ({}),
    },
    sendText: {
      type: String,
      default: "发 送",
    },
    sendKey: {
      type: Function,
      default(e) {
        return e.keyCode == 13;
      },
    },
  },
  data() {
    this.clipboardBlob = null;
    return {
      //剪切板图片URL
      clipboardUrl: "",
      submitDisabled: true,
      proxyTools: [],
      accept: "",
    };
  },
  created() {
    if (this.tools && this.tools.length > 0) {
      this.initTools(this.tools);
    } else {
      this.initTools([
        { name: "emoji" },
      ]);
    }
    this.IMUI.$on("change-contact", () => {
      this.closeClipboardImage();
    });
  },
  render() {
    const toolLeft = [];
    // const toolRight = [];
    const user = this.zhuboInfo
    this.proxyTools.forEach(({ name, title, render, click, isRight }) => {
      click = click || new Function();
      const classes = [
        "yumy-editor__tool-item",
        { "yumy-editor__tool-item--right": isRight },
      ];
      let node;
      if (name == "emoji") {
        node =
          emojiData.length == 0 ? (
            ""
          ) : (
            <yumy-popover class="yumy-editor__emoji">
              <template slot="content">{this._renderEmojiTabs()}</template>
              <div class={classes} title={title}>
                {render()}
              </div>
            </yumy-popover>
          );
      } else {
        node = (
          <div class={classes} on-click={click} title={title}>
            {render()}
          </div>
        );
      }
      // if (isRight) {
      //   toolRight.push(node);
      // } else {
      //   toolLeft.push(node);
      // }
      toolLeft.push(node);
    });

    // emoji

    // <div class="yumy-editor__tool">
    //   <div class="yumy-editor__tool-left">{toolLeft}</div>
    //   <div class="yumy-editor__tool-right">{toolRight}</div>
    // </div>

    return (
      <div class="yumy-editor">
        {this.clipboardUrl && (
          <div class="yumy-editor__clipboard-image">
            <img src={this.clipboardUrl} />
            <div>
              <yumy-button
                style={{ marginRight: "10px" }}
                on-click={this.closeClipboardImage}
                color="grey"
              >
                取消
              </yumy-button>
              <yumy-button on-click={this.sendClipboardImage}>
                发送图片
              </yumy-button>
            </div>
          </div>
        )}
        <input
          style="display:none"
          type="file"
          multiple="multiple"
          ref="fileInput"
          accept={this.accept}
          onChange={this._handleChangeFile}
        />
        <div class="yumy-editor__info">
          <div class="yumy-editor__one yumy-editor__tool-left">
            <el-tooltip class="item" effect="light" content={user.displayName} placement="top-start">
              <span class="yumy-username">{user.displayName}</span>
            </el-tooltip>
            <span class="yumy-id">({user.uid})</span>
            <span v-show={Number(user.onlineStatus)} class="yumy-online">online</span>
            <span class="yumy-info">国家: {user.country}</span>
            <span class="yumy-info">语言: {user.language}</span>
            <span class="yumy-info">年龄: {user.age}</span>
            <span class="yumy-info">性别: {user.gender?'女':'男'}</span>
            <span class="yumy-info" on-click={this.openHosterImgsLists}><el-button size="mini" type="primary">主播相册</el-button></span>
            {toolLeft}
          </div>
          <div class="one">
            <span>{user?'':''}</span>
          </div>

        </div>
        <div class="yumy-editor__inner">
          <div
            class="yumy-editor__input"
            ref="textarea"
            contenteditable="true"
            on-keyup={this._handleKeyup}
            on-keydown={this._handleKeydown}
            on-paste={this._handlePaste}
            on-click={this._handleClick}
            spellcheck="false"
          />
        </div>
        <div class="yumy-editor__footer">
          <div class="yumy-editor__tip">
            {useScopedSlot(
              this.IMUI.$scopedSlots["editor-footer"],
              "使用 Enter 快捷发送消息",
            )}
          </div>
          <div class="yumy-editor__submit">
            <yumy-button
              disabled={this.submitDisabled}
              on-click={this._handleSend}
            >
              {this.sendText}
            </yumy-button>
          </div>
        </div>
      </div>
    );
  },
  methods: {
    closeClipboardImage() {
      this.clipboardUrl = "";
      this.clipboardBlob = null;
    },
    sendClipboardImage() {
      if (!this.clipboardBlob) return;
      this.$emit("upload", this.clipboardBlob);
      this.closeClipboardImage();
    },
    /**
     * 初始化工具栏
     */
    initTools(data) {
      if (!data) return;
      const defaultTools = [
        {
          name: "emoji",
          title: "表情",
          click: null,
          // eslint-disable-next-line no-unused-vars
          render: menu => {
            return <i class="yumy-icon-emoji" />;
          },
        },
        {
          name: "uploadFile",
          title: "文件上传",
          click: () => this.selectFile("*"),
          // eslint-disable-next-line no-unused-vars
          render: menu => {
            return <i class="yumy-icon-folder" />;
          },
        },
        {
          name: "uploadImage",
          title: "图片上传",
          click: () => this.selectFile("image/*"),
          // eslint-disable-next-line no-unused-vars
          render: menu => {
            return <i class="yumy-icon-image" />;
          },
        },
      ];
      let tools = [];
      if (Array.isArray(data)) {
        const indexMap = {
          emoji: 0,
          uploadFile: 1,
          uploadImage: 2,
        };
        const indexKeys = Object.keys(indexMap);
        tools = data.map(item => {
          if (indexKeys.includes(item.name)) {
            return {
              ...defaultTools[indexMap[item.name]],
              ...item,
            };
          }
          return item;
        });
      } else {
        tools = defaultTools;
      }
      this.proxyTools = tools;
    },
    _saveLastRange() {
      lastSelectionRange = selection.getRangeAt(0);
    },
    _focusLastRange() {
      this.$refs.textarea.focus();
      if (lastSelectionRange) {
        selection.removeAllRanges();
        selection.addRange(lastSelectionRange);
      }
    },
    _handleClick() {
      this._saveLastRange();
    },
    openHosterImgsLists(){
      store.dispatch('status/setOpenState')
    },
    _renderEmojiTabs() {
      const renderImageGrid = items => {
        return items.map(item => (
          // <img
          //   src={item.src}
          //   title={item.title}
          //   class="yumy-editor__emoji-item"
          //   on-click={() => this._handleSelectEmoji(item)}
          // />
          <span class="yumy-editor__emoji-item" on-click={() => this._handleSelectEmoji(item)}>{item.name}</span>
        ));
      };
      if (emojiData[0].label) {
        const nodes = emojiData.map((item, index) => {
          return (
            <div slot="tab-pane" index={index} tab={item.label}>
              {renderImageGrid(item.children)}
            </div>
          );
        });
        return <yumy-tabs style="width: 412px">{nodes}</yumy-tabs>;
      } else {
        return (
          <div class="yumy-tabs-content" style="width:406px">
            {renderImageGrid(emojiData)}
          </div>
        );
      }
    },
    _handleSelectEmoji(item) {
      this._focusLastRange();
      // exec(`<img emoji-name="${item.name}" src="${item.src}"></img>`);
      exec(`<span>${item.name}</span>`);
      this._checkSubmitDisabled();
      this._saveLastRange();
    },
    async selectFile(accept) {
      this.accept = accept;
      await this.$nextTick();
      this.$refs.fileInput.click();
    },
    _handlePaste(e) {
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      const text = clipboardData.getData("Text");
      if (text) {
        if (window.clipboardData) {
          this.$refs.textarea.innerHTML = text;
        } else {
          exec(text, "insertText");
        }
      } else {
        const { blob, blobUrl } = this._getClipboardBlob(clipboardData);
        this.clipboardBlob = blob;
        this.clipboardUrl = blobUrl;
      }
    },
    _getClipboardBlob(clipboard) {
      let blob, blobUrl;
      for (var i = 0; i < clipboard.items.length; ++i) {
        if (
          clipboard.items[i].kind == "file" &&
          clipboard.items[i].type.indexOf("image/") !== -1
        ) {
          blob = clipboard.items[i].getAsFile();
          blobUrl = (window.URL || window.webkitURL).createObjectURL(blob);
        }
      }
      return { blob, blobUrl };
    },
    // eslint-disable-next-line no-unused-vars
    _handleKeyup(e) {
      this._saveLastRange();
      this._checkSubmitDisabled();
    },
    _handleKeydown(e) {
      //ctrl+enter
      if(e.ctrlKey && e.keyCode === 13){
          this.textareaRange()
      } else  if (this.submitDisabled == false && this.sendKey(e)) {
        e.preventDefault()
        this._handleSend();
      }
      
    },
    textareaRange() { 
        var el = this.$refs.textarea
        var range = document.createRange();
        //返回用户当前的选区
        var sel =  document.getSelection();
        //获取当前光标位置
        var offset = sel.focusOffset
        //div当前内容
        var content = el.innerHTML
        //添加换行符\n
        el.innerHTML = content.slice(0,offset)+'\n'+content.slice(offset)
        //设置光标为当前位置
        range.setStart(el.childNodes[0], offset+1);
        //使得选区(光标)开始与结束位置重叠
        range.collapse(true);
        //移除现有其他的选区
        sel.removeAllRanges();
        //加入光标的选区
        sel.addRange(range);
      },
    getFormatValue() {
      return this.IMUI.emojiImageToName(
        this.$refs.textarea.innerHTML
          .replace(/<br>|<\/br>/, "")
          .replace(/<div>|<p>/g, "\r\n")
          .replace(/<\/div>|<\/p>/g, "")
      );
      // return this.IMUI.emojiImageToName(this.$refs.textarea.innerHTML);
    },
    _checkSubmitDisabled() {
      this.submitDisabled = !clearHtmlExcludeImg(
        this.$refs.textarea.innerHTML.trim(),
      );
    },
    // eslint-disable-next-line no-unused-vars
    _handleSend(e) {
      const text = this.getFormatValue();
      this.$emit("send", text);
      this.clear();
      this._checkSubmitDisabled();
    },
    // eslint-disable-next-line no-unused-vars
    _handleChangeFile(e) {
      const { fileInput } = this.$refs;
      Array.from(fileInput.files).forEach(file => {
        this.$emit("upload", file);
      });
      fileInput.value = "";
    },
    clear() {
      this.$refs.textarea.innerHTML = "";
    },
    initEmoji(data) {
      emojiData = data;
      this.$forceUpdate();
    },
    setValue(val) {
      this.$refs.textarea.innerHTML = this.IMUI.emojiNameToImage(val);
      this._checkSubmitDisabled();
    },
  },
};
</script>
<style lang="stylus">
@import '../styles/utils/index'
gap = 10px;
+b(yumy-editor)
  height 300px
  position relative
  flex-column()
  +e(info)
    border-top 1px solid #ddd
    text-align left
  +e(one)
    height 40px
    padding 5px 5px
    line-height 40px
    border-bottom 1px solid #ddd
  +e(tool)
    display flex
    height 40px
    align-items center
    justify-content space-between
    padding 5px 5px
    border-top 1px solid #eee
    border-bottom 1px solid #ddd
  +e(tool-left){
    display flex
  }
  +e(tool-right){
    display flex
  }
  +e(tool-item)
    cursor pointer
    padding 4px gap
    height 28px
    line-height 24px;
    color #d4380d
    transition all ease .3s
    font-size 12px
    padding-top: 8px
    [class^='yumy-icon-']
      line-height 28px
      font-size 26px
      font-weight: 500
    &:hover
      color #333
    +m(right){
      margin-left:auto;
    }
  +e(inner)
    flex 1
    overflow-x hidden
    overflow-y auto
    scrollbar-light()
  +e(clipboard-image)
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    flex-column()
    justify-content center
    align-items center
    background #f4f4f4
    z-index 1
    img
      max-height 66%
      max-width 80%
      background #e9e9e9
      //box-shadow 0 0 20px rgba(0,0,0,0.15)
      user-select none
      cursor pointer
      border-radius 4px
      margin-bottom 10px
      border 3px dashed #ddd !important
      box-sizing border-box
    .clipboard-popover-title
      font-size 14px
      color #333
  +e(input)
    height 100%
    box-sizing border-box
    border none
    outline none
    padding gap
    text-align left
    scrollbar-light()
    p,div
      margin 0
    img
      height 20px
      padding 0 2px
      pointer-events none
      position relative
      top -1px
      vertical-align middle
  +e(footer)
    display flex
    height 52px
    justify-content flex-end
    padding 0 gap
    align-items center
  +e(tip)
    margin-right 10px
    font-size 12px
    color #999
    user-select none
  +e(emoji)
    user-select none
    .yumy-popover
      background #f6f6f6
    .yumy-popover__content
      padding 0
    .yumy-popover__arrow
      background #f6f6f6
    .yumy-tabs-content
      box-sizing border-box
      padding 8px
      height 200px
      overflow-x hidden
      overflow-y auto
      scrollbar-light()
      margin-bottom 8px
  +e(emoji-item)
    cursor pointer
    padding 4px
    border-radius 4px
    font-size: 1.5rem;
    &:hover
      background #e9e9e9
</style>t
