// userstatus 0: not login 1: login 2: system logout
const state = {
  userStatus: 0,
  clientId: 0,
  token: undefined,
  
}

const mutations = {
  SET_STATUS: (state, status) => {
    state.userStatus = status
  },
  SET_CLIENTID: (state, { clientId, token }) => {
    state.clientId = clientId
    state.token = token
  },
}

const actions = {
  updateUserStatus({ commit }, status) {
    commit('SET_STATUS', Number(status))
  },
  updateClientId({ commit }, { clientId, token }) {
    commit('SET_CLIENTID', { clientId, token })
  },
  systemLogout({ commit }, status){
    commit('SET_STATUS', Number(status))
  }
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
