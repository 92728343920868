<script>
import { isString, isToday } from "@/utils/validate";
import { timeFormat, useScopedSlot } from "@/utils";
export default {
  name: "YumyContact",
  components: {},
  inject: {
    IMUI: {
      from: "IMUI",
      default() {
        return this;
      },
    },
  },
  data() {
    return {};
  },
  props: {
    contact: Object,
    simple: Boolean,
    timeFormat: {
      type: Function,
      default(val) {
        return timeFormat(val, isToday(val) ? "h:i" : "y/m/d");
      },
    },
  },
  render() {
    let isDisable = true
    if(this.simple) {
      isDisable=this.contact.onlineStatus?true:false;
    }
    return (
      <div
        class={["yumy-contact", { "yumy-contact--name-center": this.simple }]}
        title={this.contact.displayName}
        v-show={isDisable}
        on-click={e => this._handleClick(e, this.contact)}
      >
        {useScopedSlot(
          this.$scopedSlots.default,
          this._renderInner(),
          this.contact,
        )}
      </div>
    );
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    _renderInner() {
      const { contact } = this;
      return [
        <yumy-badge
          count={!this.simple ? contact.unread : 0}
          class="yumy-contact__avatar"
        >
          <yumy-avatar size={40} src={contact.avatar} />
        </yumy-badge>,
        <div class="yumy-contact__inner">
          <p class="yumy-contact__label">
            <span v-show={Number(contact.userInfo.onlineStatus)} class="yumy-contact__online"></span>
            {this.simple && (
              <span class="yumy-contact__namesimple">{contact.displayName}</span>
            )}
            {!this.simple && (
            <span class="yumy-contact__name">{contact.displayName}</span>
            )}
            {!this.simple && (
              <span class="yumy-contact__time">
                {this.timeFormat(contact.lastSendTime)}
              </span>
            )}
          </p>
          {!this.simple && (
            <p class="yumy-contact__content">
              {isString(contact.lastContent) ? (
                <span domProps={{ innerHTML: contact.lastContent }} />
              ) : (
                contact.lastContent
              )}
            </p>
          )}
        </div>,
      ];
    },
    _handleClick(e, data) {
      this.$emit("click", data);
    },
  },
};
</script>
<style lang="stylus">
@import '../styles/utils/index'
+b(yumy-contact)
  padding 10px 14px
  cursor pointer
  user-select none
  box-sizing border-box
  overflow hidden
  background #efefef
  text-align left
  p
    margin 0
  +m(active)
    background #bebdbd
  &:hover:not(.yumy-contact--active)
    background #e3e3e3
    .el-badge__content
      border-color #ddd
  +e(avatar)
    float left
    margin-right 10px
    img
      display block
    .ant-badge-count
      display inline-block
      padding 0 4px
      height 18px
      line-height 18px
      min-width 18px
      top -4px
      right 7px
  +e(label)
    display flex
    align-items: center;
  +e(time)
    font-size 12px
    line-height 18px
    padding-left 6px
    color #999
    white-space nowrap
  +e(name)
    display block
    width: 135px
    ellipsis()
  +e(namesimple)
    display block
    width: 100%
    ellipsis()
  +e(online)
    display block
    width 10px
    height 10px
    background-color: #43A047
    border-radius: 50%
    margin-right: 5px
  +e(content)
    font-size 12px
    color #999
    height 18px
    line-height 18px
    margin-top 1px !important
    ellipsis()
    img
      height 14px
      display inline-block
      vertical-align middle
      margin 0 1px
      position relative
      top -1px
  +m(name-center)
    +e(label)
      padding-bottom 0
      line-height 38px
</style>
