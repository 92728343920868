import root from '@/json/proto'
import store from '@/store'

const frame = root.lookupType('proto.frame.Frame')

function toFrame(cmd, body, to, form ) {
  const clientId = store.state.login.clientId
  const targetId = to || clientId
  const fromId = form || clientId
  const sendFrame = frame.create({
    version: 1,
    cmd: cmd,
    seq: 1,
    targetId,
    fromId,
    packageName: 'com.yumy.webim',
    body: body,
  })
  return frame.encode(sendFrame).finish()
}

function encode(type, json) {
  const ret = type.create(json)
  return type.encode(ret).finish()
}

function decode(type, proto) {
  const buff = new Uint8Array(proto)
  return type.decode(buff)
}

function decodeFrame(proto) {
  const buff = new Uint8Array(proto)
  return frame.decode(buff)
}

const pf = {
  encode,
  decode,
  toFrame,
  decodeFrame
}

export default pf
