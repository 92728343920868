import serv from './receive'
import store from '@/store'
import cmdim from './cmdim.js'

function initValue() {
  ws.websocket = null
  ws.ReconnectTimes = 3
  ws.heatBeatTimer = null
  geturl()
}

function geturl(){
  if('development'===process.env.NODE_ENV) {
    ws.url = 'wss://msg.yumy.live'
    // ws.url = 'wss://msg.matchchat.club'
  } else {
    // ws.url = 'wss://msg.matchchat.club'
    ws.url = 'wss://msg.yumy.live'
  }
}

function restart(){
  if (ws.isReconnect) return
  ws.isReconnect = true
  const clientId = store.state.login.clientId
  const token = store.state.login.token
  if(clientId&&token) {
    reconnect()
  } else {
    store.dispatch("login/updateUserStatus", 0)
  }
}
function reconnect() {
  if (ws.ReconnectTimer) {
    clearInterval(ws.ReconnectTimer)
    ws.ReconnectTimer = null
  }
  ws.ReconnectTimer = setInterval(() => {
    if(ws.isReconnect) {
      initValue()
      createWs()
    }
  }, ws.RecommectIniterval)
}
function sendInit(){
  ws.isReconnect = false
  if (ws.ReconnectTimer) {
    clearInterval(ws.ReconnectTimer)
    ws.ReconnectTimer = null
  }
  cmdim.restart()
  store.dispatch("websocket/updateStatus", ws.websocket.readyState)
}

function createWs() {
  if(ws.websocket && ws.websocket.readyState === ws.websocket.OPEN ){
    ws.isReconnect = false
    if (ws.ReconnectTimer) {
      clearInterval(ws.ReconnectTimer)
      ws.ReconnectTimer = null
    }
    return
  }
  if (ws.websocket) {
    if (ws.websocket.readyState !== ws.websocket.OPEN || ws.websocket.readyState !== ws.websocket.CONNECTING) {
      setTimeout(() => {
        reconnect()
      }, 1000)
    } else {
      return
    }
  }
  ws.websocket = new WebSocket(ws.url)
  setTimeout(() => {
    if(ws.websocket && ws.websocket.readyState === 1 ){
      sendInit()
    } else {
      createWs()
    }
  }, 1000)
  ws.websocket.onmessage = (e) => receive(e)
  ws.websocket.onopen = () => open
  ws.websocket.onerror = (e) => error(e)
  ws.websocket.onclose = (e) => onClose(e)
}


function init() {
  geturl()
  console.error('WebSocket Initing...', ws)
  connect(0)
}
function connect(from){
  if(ws.websocket&&ws.websocket.readyState) {
    console.error('1.2object', ws.websocket.readyState);
  }
  if(Number(ws.ReconnectTimes)) {
    ws.ReconnectTimes = Number(ws.ReconnectTimes) -1
    if(ws.websocket && ws.websocket.readyState === ws.websocket.OPEN ){
      store.dispatch("websocket/updateStatus", ws.websocket.readyState)
      ws.isReconnect = false
      ws.ReconnectTimer = null
      return
    }
    if (ws.websocket) {
      if (ws.websocket.readyState !== ws.websocket.OPEN || ws.websocket.readyState !== ws.websocket.CONNECTING) {
        setTimeout(() => {
          ws.init()
        }, 1000)
      } else {
        return
      }
    }
    ws.websocket = new WebSocket(ws.url)
    setTimeout(() => {
      if(ws.websocket && ws.websocket.readyState === 1 ){
        if(from) {
          cmdim.restart()
        } else {
          store.dispatch("websocket/updateStatus", ws.websocket.readyState)
        }
      } else {
        ws.init()
      }
    }, 1000)
    ws.websocket.onmessage = (e) => receive(e)
    ws.websocket.onopen = () => open
    ws.websocket.onerror = (e) => error(e)
    ws.websocket.onclose = (e) => onClose(e)
  } else {
    close(5)
  }
}

function open() {
  console.error('WebSocket connect success!')
}

function error(e) {
  console.error('websocket.error', e)
  if (ws.websocket) {
    ws.websocket.close()
  }
}

function onClose(e) {
  console.error('websocket.onClose', e)
  close(6)
}

function receive(rsp) {
  serv.receive(rsp)
}

function send(buffer) {
  if(ws.websocket) {
    if (ws.websocket.readyState === ws.websocket.OPEN) {
      setTimeout(() => {
        ws.websocket.send(buffer)
      }, 200)
    } else if (ws.websocket.readyState === ws.websocket.CONNECTING) {
      setTimeout(() => {
        ws.send(buffer)
      }, 500)
    } else if (ws.websocket.readyState === ws.websocket.CLOSED) {
      restart()
    }
  } else {
    restart()
  }
}

function heart() {
  if (ws.heatResProto) {
    if (ws.heatBeatTimer) {
      clearInterval(ws.heatBeatTimer)
      ws.heatBeatTimer = null
    }
    ws.send(ws.heatResProto)
    ws.heatBeatTimer = setInterval(() => {
      ws.send(ws.heatResProto)
      getstatus()
    }, ws.heatBeatIniterval)
  }
}

function getstatus(){
  const pb = cmdim.getUserState()
  if(pb) {
    ws.send(pb)
  }
}

// from: 5: times=3 6: server error
function close(from) {
  if (ws.websocket) {
    ws.websocket.close()
  }
  if(ws.heatBeatTimer) {
    clearInterval(ws.heatBeatTimer)
  }
  ws.heatBeatTimer = null
  store.dispatch("websocket/updateStatus", from)
  setTimeout(() => {
    if (Number(store.getters.userStatus)===1) {
      restart()
    }
  }, 500)
}

const ws = {
  websocket: null,
  url: '',
  heatBeatTimer: null,
  heatBeatIniterval: 20000,
  heatResProto: cmdim.heat(),
  isReconnect: false,
  ReconnectTimes: 3,
  ReconnectTimer: null,
  RecommectIniterval: 20000,
  init: init,
  send: send,
  open: open,
  heart: heart,
  close: close,
  getstatus,
  geturl,
  restart,
  error: error
}

export default ws
