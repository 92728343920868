// state: 
// 0 (WebSocket.CONNECTING) 正在链接中
// 1 (WebSocket.OPEN) 已经链接并且可以通讯
// 2 (WebSocket.CLOSING) 连接正在关闭
// 3 (WebSocket.CLOSED) 连接已关闭或者没有链接成功
const state = {
  status: 0
}

const mutations = {
  SET_STATUS: (state, status) => {
    state.status = status
  }

}

const actions = {
  updateStatus({ commit }, status) {
    commit('SET_STATUS', Number(status))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
