/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.addJSON({
  proto: {
    nested: {
      audio: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbAudioRoomCommon"
        },
        nested: {
          RetCode: {
            values: {
              kSuccess: 0,
              kSeatFull: 4000,
              kSeatOccupy: 4001,
              kGameNotSupport: 4002,
              kAlreadySit: 4003,
              kForbidSit: 4004,
              kInOtherRoom: 4005,
              kRoomNotExist: 4006,
              kPasswdInvalid: 4007,
              kSeatLocked: 4008,
              kGiftNotExist: 4009,
              kOutOfBalance: 2101,
              kInKickOut: 4011,
              kNotInRoom: 4012,
              kRedEnvelopeClosed: 4013,
              kRedEnvIsEmpty: 4014,
              kDuplicateRedEnv: 4015,
              kAdminSetFailed: 4016,
              kAdminExceedLimit: 4017,
              kAdminOpNotPermitted: 4018,
              kSendGiftFailedForNobody: 4019,
              kTrickNotExist: 4020,
              kNoBarrage: 4021,
              kSuperWinnerRejectJoin: 4022,
              kSuperWinnerAlreadyJoined: 4023,
              kSuperWinnerPlayersFull: 4024,
              kTeamPKOngoing: 4025,
              kSuperRedNotStart: 4026,
              kSuperRedUnlock: 4027,
              kSuperRedUnlock60s: 4028,
              kExclusiveGift4Vip4: 4029,
              kRoomBan: 4030,
              kExclusiveGift4Vip5: 4031,
              kPreventKickout: 4032,
              kPreventBan: 4033,
              kPreventSeatUnlock: 4034,
              kWinRateExceed: 4035,
              kGameOngoing: 4036,
              kSwHbRaiseFailed: 4037,
              kRedMaintaining: 4038,
              kAppUpdate: 4039,
              kRedSendWealthLevel1: 4040,
              kRedSendWealthLevel2: 4041,
              kGamePlayerExceed: 4042,
              kGamePlayerNeed2: 4043,
              kGameOnPenaltyTime: 4044,
              kGameStatusError: 4045,
              kRoomModeError: 4046,
              kGameMaintaining: 4047
            }
          },
          RoomStatus: {
            values: {
              kSilence: 0,
              kHosting: 1,
              kNoHost: 2,
              kLiveBanned: 3
            }
          },
          RoomSession: {
            fields: {
              roomId: {
                rule: "required",
                type: "fixed64",
                id: 1
              },
              uid: {
                rule: "required",
                type: "fixed64",
                id: 2
              },
              type: {
                rule: "required",
                type: "uint32",
                id: 3
              }
            }
          },
          AudioSeatAct: {
            values: {
              kSeatLock: 1,
              kSeatUnlock: 2,
              kSetRemove: 3,
              kSeatCloseMic: 4,
              kSeatOpenMic: 5,
              kSeatSitdown: 6,
              kSeatStandup: 7,
              kSeatGrab: 8,
              kSeatRenew: 9
            }
          },
          AudioSeatInfo: {
            fields: {
              roomId: {
                type: "fixed64",
                id: 1
              },
              roomType: {
                type: "uint32",
                id: 2
              },
              hostId: {
                type: "fixed64",
                id: 3
              },
              seatId: {
                type: "uint32",
                id: 4
              },
              status: {
                type: "uint32",
                id: 5
              },
              supportMic: {
                type: "uint32",
                id: 6
              },
              streamId: {
                type: "string",
                id: 7
              },
              startTime: {
                type: "fixed64",
                id: 8
              },
              endTime: {
                type: "fixed64",
                id: 9
              },
              leftTime: {
                type: "fixed64",
                id: 10
              },
              userInfo: {
                type: "common.UserInfo",
                id: 11
              }
            }
          },
          AudioRoomInfo: {
            fields: {
              roomId: {
                type: "fixed64",
                id: 1
              },
              roomType: {
                type: "uint32",
                id: 2
              },
              name: {
                type: "string",
                id: 3
              },
              gameType: {
                type: "uint32",
                id: 4
              },
              topicType: {
                type: "uint32",
                id: 5
              },
              coverFid: {
                type: "string",
                id: 6
              },
              score: {
                type: "uint32",
                id: 7
              },
              countryCode: {
                type: "string",
                id: 8
              },
              userInfo: {
                type: "common.UserInfo",
                id: 9
              }
            }
          },
          StickerInfo: {
            fields: {
              stickerId: {
                type: "uint32",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              image: {
                type: "string",
                id: 3
              },
              playTimes: {
                type: "uint32",
                id: 4
              }
            }
          },
          AudioGiftInfo: {
            fields: {
              giftId: {
                type: "uint32",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              image: {
                type: "string",
                id: 3
              },
              effect: {
                type: "string",
                id: 4
              },
              type: {
                type: "uint32",
                id: 5
              },
              isGlobal: {
                type: "bool",
                id: 6,
                options: {
                  "default": false
                }
              },
              hasMusic: {
                type: "bool",
                id: 7
              },
              price: {
                type: "uint32",
                id: 8
              },
              isLuck: {
                type: "bool",
                id: 9
              },
              luckDeepLink: {
                type: "string",
                id: 10
              },
              scene: {
                type: "uint32",
                id: 11,
                options: {
                  "default": 3
                }
              },
              num: {
                type: "uint32",
                id: 12,
                options: {
                  "default": 1
                }
              }
            }
          }
        }
      },
      common: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbCommon"
        },
        nested: {
          Cmd: {
            values: {
              kHandshakeReq: 257,
              kHandshakeRsp: 258,
              kHeartbeatReport: 259,
              kHeartbeatRsp: 260,
              kKickout: 262,
              kLogout: 265,
              kOnlineStatusReport: 273,
              kOnlineStatusReportRsp: 274,
              kUpdateHandshakeInfoReq: 289,
              kUpdateHandshakeInfoRsp: 290,
              kSendChatMsgReq: 1281,
              kSendChatMsgRsp: 1282,
              kSyncMsgStatus: 1283,
              kMsgStatusNty: 1284,
              kNewChatMsg: 1286,
              kMsgStatusReport: 1287,
              kMsgStatusReportRsp: 1288,
              kMsgTypeTypingReq: 1289,
              kMsgTypeTypingNotify: 1296,
              kQueryMyOfflineMsgReq: 1297,
              kQueryMyOfflineMsgRsp: 1298,
              kQueryConversationMsgReq: 1299,
              kQueryConversationMsgRsp: 1300,
              kMsgPassThroughS2C: 1312,
              kSendSysNotifyReq: 1313,
              kSysNotify: 1316,
              kSysNotifyAck: 1317,
              kQueryChatListReq: 1318,
              kQueryChatListRsp: 1319,
              kQueryHistoryListReq: 1320,
              kQueryHistoryListRsp: 1321,
              kNewChatNotify: 1328,
              kQueryOnlineStatusReq: 1329,
              kQueryOnlineStatusRsp: 1330,
              kQueryUserImagesReq: 1331,
              kQueryUserImagesRsp: 1332,
              kMsgTypeRecallReq: 1536,
              kMsgTypeRecallRsp: 1537,
              kMsgTypeRecallNotify: 1538,
              kMsgTypeRecallAck: 1539,
              kMsgTypeImgRecallReq: 1540,
              kC2SMediaCallReq: 1792,
              kS2CMediaCallRsp: 1793,
              kS2CMediaCallNotify: 1794,
              kS2CMediaCallNotifyRsp: 1810,
              kC2SMediaCallNtyAck: 1795,
              kS2CMediaCallNtyAckRsp: 1796,
              kC2SMediaCallPermissionReq: 1797,
              kS2CMediaCallPermissionRsp: 1798,
              kMsgTypeInternal: 4096,
              kMQApiReport: 32768,
              kInnerHandshakeReq: 4352,
              kInnerHandshakeRes: 4353,
              kSubUserOnlineStatusReq: 4608,
              kSubUserOnlineStatusRsp: 4609,
              kCancelSubUserOnlineStatusReq: 4610,
              kCancelSubUserOnlineStatusRsp: 4611,
              kUserOnlineStatusChangeNotify: 4612,
              kFaceDiscoverNotify: 4864,
              kCreateGameRoomReq: 769,
              kCreateGameRoomRsp: 770,
              kEnterGameReq: 771,
              kEnterGameRsp: 772,
              kSimpleBetReq: 773,
              kSimpleBetRsp: 774,
              kGameChannel2SvrReq: 775,
              kGameChannel2SvrRsp: 776,
              kGameChannelNotifyApp: 778,
              kExitGameReq: 779,
              kExitGameRsp: 780,
              kMultiBetReq: 781,
              kMultiBetRsp: 782,
              kGameQueryBalanceReq: 801,
              kGameUpdateBalanceRsp: 802,
              kMicoGameHeartbeatReq: 1021,
              kMicoGameHeartbeatRsp: 1022
            }
          },
          RetCode: {
            values: {
              Success: 0,
              TimeOut: 1,
              NeedLogin: 2,
              SvrInnerError: 3,
              AlreadyInProgress: 4,
              OpNotPermitted: 5,
              InsufficientBalance: 6,
              OtherBusy: 7,
              MediaCallEnd: 8,
              MediaCallStart: 9,
              MediaCallCancel: 10,
              MediaCallRoomNotExist: 11
            }
          },
          RspHead: {
            fields: {
              code: {
                rule: "required",
                type: "int32",
                id: 1
              },
              desc: {
                type: "string",
                id: 2
              }
            }
          },
          UserInfo: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              avatar: {
                type: "string",
                id: 2
              },
              nickname: {
                type: "string",
                id: 3
              },
              gender: {
                type: "uint32",
                id: 4
              },
              birthday: {
                type: "fixed64",
                id: 5
              },
              country: {
                type: "string",
                id: 6
              },
              isVip: {
                type: "bool",
                id: 7
              },
              userType: {
                type: "uint32",
                id: 8
              }
            }
          }
        }
      },
      audiomsg: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbLiveMessage"
        },
        nested: {
          MsgType: {
            values: {
              MsgTypeUnknown: 0,
              MsgTypePicFile: 1,
              MsgTypeVoice: 2,
              MsgTypeDocFile: 3,
              MsgTypeVideoFile: 4,
              MsgTypeLocate: 5,
              MsgTypeUserCard: 6,
              MsgTypeAppCard: 7,
              MsgTypePrivacyPicFile: 8,
              MsgTypeViewPrivacyPicFile: 9,
              MsgTypeSys: 10,
              MsgTypePlainText: 12,
              MsgTypeSticker: 13,
              MsgTypeGuidence: 14,
              MsgTypeQuestion: 15,
              MsgTypeLike: 19,
              MsgTypeTypingText: 20,
              MsgTypeCallVoiceStart: 21,
              MsgTypeCallVideoStart: 22,
              MsgTypeCallDecline: 25,
              MsgTypeCallCancel: 26,
              MsgTypeCallEnd: 27,
              MsgTypeShareFeed: 52,
              MsgTypeShareUser: 53,
              MsgTypeSayHi: 54,
              MsgTypeGift: 59,
              MsgTypeViewProfile: 60,
              MsgTypeVideoPreload: 61,
              MsgTypeGiftRequest: 62,
              MsgTypeInviteEnterSeat: 70,
              MsgTypeInviteEnterRoom: 71,
              MsgTypeLiveGiftInfo: 72,
              MsgTypeAudioRoomAction: 73,
              MsgTypeAudioRoomSeatAction: 74,
              MsgTypeEmoji: 75,
              MsgTypeRoomRenew: 76,
              MsgTypeRoomSwitch: 77,
              MsgTypePassthrough: 10000
            }
          },
          GuidenceInfo: {
            fields: {
              image: {
                type: "string",
                id: 1
              },
              text: {
                type: "string",
                id: 2
              },
              deepLink: {
                type: "string",
                id: 3
              }
            }
          },
          Text: {
            fields: {
              content: {
                type: "bytes",
                id: 1
              },
              atUserList: {
                rule: "repeated",
                type: "AtUserInfo",
                id: 2
              }
            }
          },
          Emoji: {
            fields: {
              content: {
                type: "string",
                id: 1
              },
              seatId: {
                type: "uint32",
                id: 2
              },
              emojiType: {
                type: "uint32",
                id: 3,
                options: {
                  "default": 1
                }
              }
            }
          },
          PicType: {
            values: {
              kPicTypeNormal: 0,
              kPicTypeGif: 1
            }
          },
          Picture: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              md5: {
                type: "bytes",
                id: 4
              },
              size: {
                type: "uint32",
                id: 5
              },
              heigh: {
                type: "uint32",
                id: 6
              },
              width: {
                type: "uint32",
                id: 7
              }
            }
          },
          PrivacyPicture: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              md5: {
                type: "bytes",
                id: 4
              },
              size: {
                type: "uint32",
                id: 5
              },
              heigh: {
                type: "uint32",
                id: 6
              },
              width: {
                type: "uint32",
                id: 7
              },
              diamond: {
                type: "uint32",
                id: 8
              },
              expire: {
                type: "uint32",
                id: 9
              },
              status: {
                type: "uint32",
                id: 10
              }
            }
          },
          Sticker: {
            fields: {
              packId: {
                type: "string",
                id: 1
              },
              sid: {
                type: "string",
                id: 2
              },
              fid: {
                type: "string",
                id: 3
              },
              type: {
                type: "uint32",
                id: 4
              },
              extendType: {
                type: "uint32",
                id: 5
              }
            }
          },
          Voice: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              duration: {
                type: "uint32",
                id: 4
              },
              size: {
                type: "uint64",
                id: 5
              }
            }
          },
          Video: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              md5: {
                type: "bytes",
                id: 4
              },
              thumbFid: {
                type: "string",
                id: 5
              },
              duration: {
                type: "uint32",
                id: 6
              },
              size: {
                type: "uint64",
                id: 7
              },
              heigh: {
                type: "uint32",
                id: 8
              },
              width: {
                type: "uint32",
                id: 9
              }
            }
          },
          GiftScene: {
            values: {
              normal: 0,
              mediacall: 1,
              request: 2,
              live: 3
            }
          },
          Vip: {
            fields: {
              text: {
                type: "string",
                id: 1
              },
              productName: {
                type: "string",
                id: 2
              }
            }
          },
          FollowMeInfo: {
            fields: {
              text: {
                type: "string",
                id: 1
              },
              followMeType: {
                type: "int32",
                id: 2
              }
            }
          },
          AtUserInfo: {
            fields: {
              uid: {
                type: "uint64",
                id: 1
              },
              nickname: {
                type: "string",
                id: 2
              }
            }
          },
          LiveMsg: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              fromUserType: {
                type: "uint32",
                id: 2
              },
              fromNickname: {
                type: "string",
                id: 3
              },
              avatar: {
                type: "string",
                id: 4
              },
              seq: {
                type: "fixed64",
                id: 5
              },
              roomId: {
                type: "fixed64",
                id: 6
              },
              msgId: {
                type: "string",
                id: 7
              },
              timestamp: {
                type: "fixed64",
                id: 8
              },
              contentType: {
                type: "uint32",
                id: 9
              },
              content: {
                type: "bytes",
                id: 10
              }
            }
          },
          S2CLiveMsgRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              roomId: {
                type: "fixed64",
                id: 3
              },
              seq: {
                type: "fixed64",
                id: 4
              },
              msgId: {
                type: "string",
                id: 5
              },
              timestamp: {
                type: "fixed64",
                id: 6
              },
              contentType: {
                type: "uint32",
                id: 7
              }
            }
          },
          LiveInviteEnterSeat: {
            fields: {
              roomInfo: {
                type: "audio.AudioRoomInfo",
                id: 1
              },
              seatNo: {
                type: "uint32",
                id: 3
              },
              toUin: {
                rule: "repeated",
                type: "fixed64",
                id: 4,
                options: {
                  packed: false
                }
              }
            }
          },
          LiveInviteEnterRoom: {
            fields: {
              roomInfo: {
                type: "audio.AudioRoomInfo",
                id: 1
              },
              toUin: {
                rule: "repeated",
                type: "fixed64",
                id: 2,
                options: {
                  packed: false
                }
              }
            }
          },
          AudioRoomAction: {
            fields: {
              roomId: {
                type: "fixed64",
                id: 1
              },
              roomType: {
                type: "uint32",
                id: 2
              },
              totalPeople: {
                type: "uint32",
                id: 3
              },
              roomActionType: {
                type: "uint32",
                id: 4,
                options: {
                  "default": 1
                }
              },
              contribute: {
                type: "uint32",
                id: 5,
                options: {
                  "default": 0
                }
              }
            }
          },
          AudioRoomSeatAction: {
            fields: {
              roomSession: {
                type: "audio.RoomSession",
                id: 1
              },
              seatActionType: {
                type: "uint32",
                id: 2
              },
              seatInfo: {
                type: "audio.AudioSeatInfo",
                id: 3
              }
            }
          },
          LiveSendGiftNty: {
            fields: {
              roomSession: {
                type: "audio.RoomSession",
                id: 1
              },
              giftInfo: {
                type: "audio.AudioGiftInfo",
                id: 2
              },
              userInfo: {
                type: "common.UserInfo",
                id: 3
              }
            }
          },
          AudioRoomRenewNty: {
            fields: {
              roomSession: {
                type: "audio.RoomSession",
                id: 1
              },
              userInfo: {
                type: "common.UserInfo",
                id: 2
              },
              expireTime: {
                type: "fixed64",
                id: 3
              }
            }
          },
          AudioRoomSwitchNty: {
            fields: {
              roomSession: {
                type: "audio.RoomSession",
                id: 1
              },
              status: {
                type: "uint32",
                id: 2
              }
            }
          }
        }
      },
      media: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbMediaCall"
        },
        nested: {
          RoomActionType: {
            values: {
              JOIN: 1,
              EXIT: 2
            }
          },
          C2SAudioRoomReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              fromUserType: {
                type: "uint32",
                id: 2
              },
              fromNickname: {
                type: "string",
                id: 3
              },
              avatar: {
                type: "string",
                id: 4
              },
              roomId: {
                type: "fixed64",
                id: 5
              },
              roomType: {
                type: "uint32",
                id: 6
              },
              commandId: {
                type: "string",
                id: 7
              }
            }
          },
          S2CAudioRoomRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              roomId: {
                type: "fixed64",
                id: 3
              },
              roomType: {
                type: "uint32",
                id: 4
              },
              commandId: {
                type: "string",
                id: 5
              }
            }
          },
          C2SRoomSeatReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              fromNickname: {
                type: "string",
                id: 2
              },
              avatar: {
                type: "string",
                id: 3
              },
              userType: {
                type: "uint32",
                id: 4
              },
              roomId: {
                type: "fixed64",
                id: 5
              },
              roomType: {
                type: "uint32",
                id: 6
              },
              seatActionType: {
                type: "uint32",
                id: 7
              },
              seatNo: {
                type: "uint32",
                id: 8
              }
            }
          },
          S2CRoomSeatRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              roomId: {
                type: "fixed64",
                id: 3
              },
              roomType: {
                type: "uint32",
                id: 4
              }
            }
          },
          RoomStatus: {
            values: {
              kSilence: 0,
              kHosting: 1,
              kNoHost: 2,
              kLiveBanned: 3
            }
          },
          AudioSeatAct: {
            values: {
              kSeatUnlock: 0,
              kSeatLock: 1,
              kSetListen: 2
            }
          },
          AudioRoomKickOutReq: {
            fields: {
              roomSession: {
                type: "audio.RoomSession",
                id: 1
              },
              uid: {
                type: "uint64",
                id: 2
              },
              userType: {
                type: "uint32",
                id: 3
              }
            }
          },
          AudioRoomKickOutRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              }
            }
          },
          AudioHeartbeatReq: {
            fields: {
              roomSession: {
                type: "audio.RoomSession",
                id: 1
              }
            }
          },
          ClientType: {
            values: {
              VIP: 1,
              ANCHOR: 2
            }
          },
          C2SLiveConnectReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              fromNickname: {
                type: "string",
                id: 4
              },
              avatar: {
                type: "string",
                id: 5
              },
              country: {
                type: "string",
                id: 6
              },
              countryIcon: {
                type: "string",
                id: 7
              },
              birthday: {
                type: "string",
                id: 8
              },
              roomId: {
                type: "string",
                id: 9
              },
              streamId: {
                type: "uint32",
                id: 10
              },
              userType: {
                type: "uint32",
                id: 11,
                options: {
                  "default": 1
                }
              }
            }
          },
          S2CLiveConnectRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              toUin: {
                type: "fixed64",
                id: 3
              },
              commandId: {
                type: "string",
                id: 4
              },
              roomId: {
                type: "string",
                id: 5
              },
              streamId: {
                type: "uint32",
                id: 6
              }
            }
          },
          S2CLiveConnectNotify: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              fromNickname: {
                type: "string",
                id: 2
              },
              avatar: {
                type: "string",
                id: 3
              },
              mediaType: {
                type: "uint32",
                id: 4
              },
              toUin: {
                type: "fixed64",
                id: 5
              },
              commandId: {
                type: "string",
                id: 6
              },
              roomId: {
                type: "string",
                id: 7
              },
              duration: {
                type: "uint32",
                id: 8
              },
              country: {
                type: "string",
                id: 9
              },
              countryIcon: {
                type: "string",
                id: 10
              },
              birthday: {
                type: "string",
                id: 11
              },
              streamId: {
                type: "uint32",
                id: 12
              }
            }
          },
          C2SLiveConnectNotifyAck: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              }
            }
          },
          C2SLiveConnectNotifyAckRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              toUin: {
                type: "fixed64",
                id: 3
              },
              commandId: {
                type: "string",
                id: 4
              },
              roomId: {
                type: "string",
                id: 5
              },
              streamId: {
                type: "uint32",
                id: 6
              }
            }
          },
          C2SLiveConnectPermissionReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              commandId: {
                type: "string",
                id: 2
              },
              roomId: {
                type: "string",
                id: 3
              },
              streamId: {
                type: "uint32",
                id: 4
              },
              userType: {
                type: "uint32",
                id: 5,
                options: {
                  "default": 1
                }
              },
              clientType: {
                type: "uint32",
                id: 6,
                options: {
                  "default": 1
                }
              }
            }
          },
          S2CLiveConnectPermissionRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              }
            }
          },
          C2SLiveConnectCloseReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              },
              clientType: {
                type: "uint32",
                id: 6,
                options: {
                  "default": 1
                }
              }
            }
          },
          S2CLiveConnectCloseRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              toUin: {
                type: "fixed64",
                id: 3
              },
              commandId: {
                type: "string",
                id: 4
              },
              roomId: {
                type: "string",
                id: 5
              },
              streamId: {
                type: "uint32",
                id: 6
              }
            }
          },
          C2SLiveConnectCloseNotify: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              }
            }
          },
          S2CLiveConnectInviteNotify: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              fromNickname: {
                type: "string",
                id: 2
              },
              avatar: {
                type: "string",
                id: 3
              },
              streamId: {
                type: "uint32",
                id: 4
              },
              toUin: {
                type: "fixed64",
                id: 5
              },
              commandId: {
                type: "string",
                id: 6
              },
              roomId: {
                type: "string",
                id: 7
              },
              country: {
                type: "string",
                id: 8
              },
              countryIcon: {
                type: "string",
                id: 9
              },
              birthday: {
                type: "string",
                id: 10
              }
            }
          },
          C2SLiveConnectCancelReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              }
            }
          },
          S2CLiveConnectCancelRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              toUin: {
                type: "fixed64",
                id: 3
              },
              commandId: {
                type: "string",
                id: 4
              },
              roomId: {
                type: "string",
                id: 5
              },
              streamId: {
                type: "uint32",
                id: 6
              }
            }
          },
          S2CLiveConnectCancelNty: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              }
            }
          },
          C2SLiveConnectHeartbeatReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              commandId: {
                type: "string",
                id: 2
              },
              streamId: {
                type: "uint32",
                id: 3
              }
            }
          },
          S2CLiveConnectAcceptNotify: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              streamId: {
                type: "uint32",
                id: 5
              }
            }
          },
          AudioVideoCallType: {
            values: {
              VoiceCallType: 1,
              VideoCallType: 2
            }
          },
          C2SMediaCallReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              fromNickname: {
                type: "string",
                id: 4
              },
              avatar: {
                type: "string",
                id: 5
              },
              country: {
                type: "string",
                id: 6
              },
              countryIcon: {
                type: "string",
                id: 7
              },
              birthday: {
                type: "string",
                id: 8
              },
              roomId: {
                type: "string",
                id: 9
              },
              mediaType: {
                type: "uint32",
                id: 10
              },
              userType: {
                type: "uint32",
                id: 11,
                options: {
                  "default": 1
                }
              },
              toUserType: {
                type: "uint32",
                id: 12,
                options: {
                  "default": 1
                }
              },
              source: {
                type: "uint32",
                id: 13
              },
              fromIsVip: {
                type: "bool",
                id: 14
              },
              gender: {
                type: "uint32",
                id: 15
              },
              translateLanguage: {
                type: "string",
                id: 16
              },
              callType: {
                type: "uint32",
                id: 17
              },
              recharge: {
                type: "int32",
                id: 18,
                options: {
                  "default": -1
                }
              },
              dataTracking: {
                type: "string",
                id: 19
              }
            }
          },
          S2CMediaCallRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              toUin: {
                type: "fixed64",
                id: 3
              },
              commandId: {
                type: "string",
                id: 4
              },
              roomId: {
                type: "string",
                id: 5
              },
              mediaType: {
                type: "uint32",
                id: 6
              },
              status: {
                type: "uint32",
                id: 7
              },
              source: {
                type: "uint32",
                id: 8
              }
            }
          },
          S2CMediaCallNotify: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              fromNickname: {
                type: "string",
                id: 2
              },
              avatar: {
                type: "string",
                id: 3
              },
              mediaType: {
                type: "uint32",
                id: 4
              },
              toUin: {
                type: "fixed64",
                id: 5
              },
              commandId: {
                type: "string",
                id: 6
              },
              roomId: {
                type: "string",
                id: 7
              },
              duration: {
                type: "uint32",
                id: 8
              },
              country: {
                type: "string",
                id: 9
              },
              countryIcon: {
                type: "string",
                id: 10
              },
              birthday: {
                type: "string",
                id: 11
              },
              isCaller: {
                type: "bool",
                id: 12
              },
              fromIsVip: {
                type: "bool",
                id: 13
              },
              gender: {
                type: "uint32",
                id: 14
              },
              point: {
                type: "fixed64",
                id: 15
              },
              gold: {
                type: "fixed64",
                id: 16
              },
              fromUserType: {
                type: "uint32",
                id: 17
              },
              translateLanguage: {
                type: "string",
                id: 18
              },
              callType: {
                type: "uint32",
                id: 19
              },
              source: {
                type: "uint32",
                id: 20
              },
              style: {
                type: "uint32",
                id: 21
              }
            }
          },
          C2SMediaCallNotifyRsp: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              mediaType: {
                type: "uint32",
                id: 5
              }
            }
          },
          C2SMediaCallNtyAck: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              mediaType: {
                type: "uint32",
                id: 5
              },
              source: {
                type: "uint32",
                id: 6
              }
            }
          },
          S2CMediaCallNtyAckRsp: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              commandId: {
                type: "string",
                id: 2
              },
              roomId: {
                type: "string",
                id: 3
              },
              mediaType: {
                type: "uint32",
                id: 4
              },
              rspHead: {
                type: "common.RspHead",
                id: 5
              }
            }
          },
          C2SMediaCallPermissionReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              commandId: {
                type: "string",
                id: 2
              },
              roomId: {
                type: "string",
                id: 3
              },
              mediaType: {
                type: "uint32",
                id: 4
              },
              userType: {
                type: "uint32",
                id: 5,
                options: {
                  "default": 1
                }
              }
            }
          },
          S2CMediaCallPermissionRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              commandId: {
                type: "string",
                id: 3
              },
              roomId: {
                type: "string",
                id: 4
              },
              startTime: {
                type: "fixed64",
                id: 5
              },
              endTime: {
                type: "fixed64",
                id: 6
              },
              leftTime: {
                type: "fixed64",
                id: 7
              },
              point: {
                type: "fixed64",
                id: 8
              },
              freeTime: {
                type: "fixed64",
                id: 9
              },
              transitionResult: {
                type: "bool",
                id: 10
              },
              perCallTime: {
                type: "fixed64",
                id: 11
              }
            }
          }
        }
      },
      frame: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbFrame"
        },
        nested: {
          ExceptionFrame: {
            fields: {
              version: {
                type: "uint32",
                id: 1
              },
              cmd: {
                type: "uint32",
                id: 2
              },
              seq: {
                type: "fixed64",
                id: 3
              },
              crc: {
                type: "fixed64",
                id: 4
              },
              body: {
                type: "bytes",
                id: 5
              },
              timestamp: {
                type: "int64",
                id: 6
              },
              srvId: {
                type: "string",
                id: 7
              },
              targetId: {
                type: "fixed64",
                id: 8
              },
              extend: {
                type: "bytes",
                id: 9
              },
              packageName: {
                type: "string",
                id: 10
              },
              fromId: {
                type: "fixed64",
                id: 11
              },
              fromUserType: {
                type: "uint32",
                id: 12,
                options: {
                  "default": 1
                }
              },
              gameId: {
                type: "uint32",
                id: 13,
                options: {
                  "default": 0
                }
              }
            }
          },
          Frame: {
            fields: {
              version: {
                rule: "required",
                type: "uint32",
                id: 1
              },
              cmd: {
                rule: "required",
                type: "uint32",
                id: 2
              },
              seq: {
                type: "fixed64",
                id: 3
              },
              crc: {
                type: "fixed64",
                id: 4
              },
              body: {
                type: "bytes",
                id: 5
              },
              timestamp: {
                type: "int64",
                id: 6
              },
              srvId: {
                type: "string",
                id: 7
              },
              targetId: {
                type: "fixed64",
                id: 8
              },
              extend: {
                type: "bytes",
                id: 9
              },
              packageName: {
                type: "string",
                id: 10
              },
              fromId: {
                type: "fixed64",
                id: 11
              },
              fromUserType: {
                type: "uint32",
                id: 12,
                options: {
                  "default": 1
                }
              },
              gameId: {
                type: "uint32",
                id: 13,
                options: {
                  "default": 0
                }
              },
              sendTimestamp: {
                type: "int64",
                id: 14
              },
              platform: {
                type: "uint32",
                id: 15
              },
              protocol: {
                type: "uint32",
                id: 16
              }
            }
          }
        }
      },
      face: {
        nested: {
          discover: {
            options: {
              java_package: "com.cloud.im.proto",
              java_outer_classname: "PbFaceDiscover"
            },
            nested: {
              FaceDiscoverNotify: {
                fields: {
                  uid: {
                    type: "fixed64",
                    id: 1
                  },
                  roomId: {
                    type: "string",
                    id: 2
                  },
                  faceDiscover: {
                    type: "bool",
                    id: 3
                  }
                }
              }
            }
          }
        }
      },
      handshake: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbHandShake"
        },
        nested: {
          OnlineStatus: {
            values: {
              kNotOnline: 0,
              kOnlineStatusForeground: 1,
              kOnlineStatusBackground: 2
            }
          },
          PlatformType: {
            values: {
              android: 1,
              ios: 16,
              pc: 17
            }
          },
          C2SHandshakeReq: {
            fields: {
              token: {
                type: "string",
                id: 2
              },
              random: {
                type: "fixed32",
                id: 3
              },
              timestamp: {
                type: "fixed64",
                id: 4
              },
              deviceId: {
                type: "string",
                id: 5
              },
              deviceToken: {
                type: "string",
                id: 7
              },
              appVersion: {
                type: "string",
                id: 8
              },
              lang: {
                type: "string",
                id: 9
              },
              os: {
                type: "string",
                id: 10
              },
              locale: {
                type: "string",
                id: 11
              },
              tz: {
                type: "sint32",
                id: 12
              },
              versionCode: {
                type: "fixed64",
                id: 13
              },
              xiaomiToken: {
                type: "string",
                id: 14
              },
              huaweiToken: {
                type: "string",
                id: 15
              },
              network: {
                type: "string",
                id: 16
              },
              model: {
                type: "string",
                id: 17
              },
              sdkVersion: {
                type: "string",
                id: 18
              },
              systemVersion: {
                type: "string",
                id: 19
              },
              digest: {
                type: "bytes",
                id: 20
              },
              platform: {
                type: "uint32",
                id: 21
              },
              position: {
                type: "OnlineStatus",
                id: 22
              }
            }
          },
          S2CHandshakeRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              random: {
                type: "fixed32",
                id: 3
              },
              timestamp: {
                type: "fixed64",
                id: 4
              },
              digest: {
                type: "bytes",
                id: 20
              }
            }
          },
          C2SUpdateHandshakeInfoReq: {
            fields: {
              deviceId: {
                type: "string",
                id: 1
              },
              deviceToken: {
                type: "string",
                id: 2
              },
              lang: {
                type: "string",
                id: 3
              },
              locale: {
                type: "string",
                id: 4
              },
              xiaomiToken: {
                type: "string",
                id: 5
              },
              huaweiToken: {
                type: "string",
                id: 6
              }
            }
          },
          C2SOnlineStatusReport: {
            fields: {
              status: {
                type: "OnlineStatus",
                id: 1
              },
              badgeNumber: {
                type: "uint32",
                id: 2
              }
            }
          },
          S2CHeartbeatRsp: {
            fields: {
              timestamp: {
                type: "fixed64",
                id: 4
              }
            }
          },
          C2SHeartbeatReport: {
            fields: {
              status: {
                type: "OnlineStatus",
                id: 1
              }
            }
          }
        }
      },
      internal: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbInternal"
        },
        nested: {
          Internal: {
            fields: {
              srvId: {
                type: "string",
                id: 1
              },
              requestId: {
                type: "string",
                id: 2
              }
            }
          }
        }
      },
      game: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbLiveGameSicBo"
        },
        nested: {
          CreateGameReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              },
              liveId: {
                type: "fixed64",
                id: 3
              },
              versionCode: {
                type: "uint32",
                id: 4
              }
            }
          },
          ExitGameReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              }
            }
          },
          GameCode: {
            values: {
              Dice: 1000,
              Number: 1001
            }
          },
          EnterGameReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              },
              liveId: {
                type: "fixed64",
                id: 3
              }
            }
          },
          EnterGameRsp: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              balance: {
                type: "uint64",
                id: 2
              },
              betRanks: {
                rule: "repeated",
                type: "uint64",
                id: 3,
                options: {
                  packed: false
                }
              },
              config: {
                type: "bytes",
                id: 4
              },
              state: {
                type: "bytes",
                id: 5
              },
              errorCode: {
                type: "uint32",
                id: 6
              }
            }
          },
          SimpleBetReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              },
              seq: {
                type: "uint64",
                id: 3
              },
              targetType: {
                type: "uint64",
                id: 4
              },
              betPoint: {
                type: "uint64",
                id: 5
              },
              liveId: {
                type: "fixed64",
                id: 6
              },
              roomId: {
                type: "fixed64",
                id: 7
              }
            }
          },
          SimpleBetRsp: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              seq: {
                type: "uint64",
                id: 2
              },
              bonusPoint: {
                type: "uint64",
                id: 3
              },
              balance: {
                type: "uint64",
                id: 4
              },
              destroy: {
                type: "bool",
                id: 5
              },
              inRoom: {
                type: "bool",
                id: 6
              },
              error: {
                type: "uint32",
                id: 7
              }
            }
          },
          BetElement: {
            fields: {
              betId: {
                type: "uint64",
                id: 1
              },
              betPoint: {
                type: "uint64",
                id: 2
              }
            }
          },
          MultiBetReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              },
              bets: {
                rule: "repeated",
                type: "BetElement",
                id: 3
              },
              seqNo: {
                type: "uint64",
                id: 4
              },
              betPoint: {
                type: "uint64",
                id: 5
              },
              liveId: {
                type: "fixed64",
                id: 6
              },
              roomId: {
                type: "fixed64",
                id: 7
              }
            }
          },
          MultiBetRsp: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              balance: {
                type: "uint64",
                id: 2
              },
              winBetId: {
                rule: "repeated",
                type: "uint64",
                id: 3,
                options: {
                  packed: false
                }
              },
              error: {
                type: "uint32",
                id: 4
              },
              bonusPoint: {
                type: "uint64",
                id: 5
              }
            }
          },
          GameChannel: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              },
              seq: {
                type: "uint64",
                id: 3
              },
              selector: {
                type: "uint64",
                id: 4
              },
              data: {
                type: "bytes",
                id: 5
              }
            }
          },
          QueryBalanceReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              }
            }
          },
          QueryBalanceRsp: {
            fields: {
              balance: {
                type: "uint64",
                id: 1
              }
            }
          },
          GameHeartbeatReq: {
            fields: {
              gameId: {
                type: "uint64",
                id: 1
              },
              hostUid: {
                type: "fixed64",
                id: 2
              }
            }
          },
          GameHeartbeatRsp: {
            fields: {}
          },
          GameUserInfo: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              avatar: {
                type: "string",
                id: 2
              },
              userName: {
                type: "string",
                id: 3
              }
            }
          },
          BeanSelector: {
            values: {
              kBossComeOnBrd: 16,
              kBossHiddenBrd: 17,
              kBossJackpotUpdateBrd: 18,
              kBossBetReq: 19,
              kBossBetRsp: 20
            }
          },
          BeanBossStatus: {
            values: {
              kHidden: 0,
              kShowing: 1
            }
          },
          BeanCode: {
            values: {
              SUCCESS: 0,
              RPC_ERROR: 1,
              NOT_ENOUGH_COIN: 2,
              JAKEPOT_HIDDEN: 3,
              JAKEPOT_SHOT_BY_OTHER: 4,
              UNKNOWN: 5
            }
          },
          BeanInitStatus: {
            fields: {
              firstBetIndex: {
                type: "uint64",
                id: 1
              },
              bossId: {
                type: "uint32",
                id: 2
              },
              bossStatus: {
                type: "uint32",
                id: 3
              },
              leftTime: {
                type: "uint32",
                id: 4
              },
              jackpotPool: {
                type: "uint64",
                id: 5
              }
            }
          },
          BeanBossComeOnBrd: {
            fields: {
              bossId: {
                type: "uint32",
                id: 1
              },
              leftTime: {
                type: "uint32",
                id: 2
              },
              jackpotPool: {
                type: "uint64",
                id: 3
              }
            }
          },
          BeanBossHiddenBrd: {
            fields: {
              bossId: {
                type: "uint32",
                id: 1
              },
              bossDead: {
                type: "bool",
                id: 2
              },
              myBonus: {
                type: "uint64",
                id: 3
              },
              winner: {
                type: "GameUserInfo",
                id: 4
              },
              winnerBonus: {
                type: "uint64",
                id: 5
              },
              leftTime: {
                type: "uint32",
                id: 6
              },
              jackpotPool: {
                type: "uint64",
                id: 7
              }
            }
          },
          BeanBossJackpotUpdateBrd: {
            fields: {
              bossId: {
                type: "uint32",
                id: 1
              },
              jackpotPool: {
                type: "uint64",
                id: 2
              }
            }
          },
          BeanBetBossReq: {
            fields: {
              bossId: {
                type: "uint32",
                id: 1
              },
              betPoint: {
                type: "uint32",
                id: 2
              },
              liveId: {
                type: "fixed64",
                id: 3
              },
              roomId: {
                type: "fixed64",
                id: 4
              }
            }
          },
          BeanBetBossRsp: {
            fields: {
              error: {
                type: "uint32",
                id: 1
              },
              balance: {
                type: "uint64",
                id: 2
              }
            }
          },
          BeanConfig: {
            fields: {
              beans: {
                rule: "repeated",
                type: "BeanInfo",
                id: 1
              }
            }
          },
          BeanInfo: {
            fields: {
              beanId: {
                type: "uint32",
                id: 1
              },
              odds: {
                type: "uint32",
                id: 2
              },
              weight: {
                type: "uint32",
                id: 3
              }
            }
          },
          number: {
            options: {
              java_package: "com.cloud.im.proto",
              java_outer_classname: "PbLiveGameNumber"
            },
            nested: {
              NumSelector: {
                values: {
                  kNumBeginBet: 256,
                  kNumBetInfo: 257,
                  kNumWaitAward: 258,
                  kNumAwardPrize: 259
                }
              },
              NumGameStatus: {
                values: {
                  kNumBet: 17,
                  kNumAnim: 18,
                  kNumAward: 19
                }
              },
              NumConfig: {
                fields: {
                  config: {
                    rule: "repeated",
                    type: "NumInfo",
                    id: 1
                  }
                }
              },
              NumInfo: {
                fields: {
                  betId: {
                    type: "uint32",
                    id: 1
                  },
                  betAmount: {
                    type: "uint32",
                    id: 2
                  }
                }
              },
              WinInfo: {
                fields: {
                  winId: {
                    type: "uint32",
                    id: 1
                  },
                  winBonus: {
                    type: "uint64",
                    id: 2
                  }
                }
              },
              NumResult: {
                fields: {
                  numberOne: {
                    type: "uint32",
                    id: 1
                  },
                  numberTwo: {
                    type: "uint32",
                    id: 2
                  },
                  numberThree: {
                    type: "uint32",
                    id: 3
                  },
                  numberSum: {
                    type: "uint32",
                    id: 4
                  }
                }
              },
              PlayerBeginBet: {
                fields: {
                  leftTime: {
                    type: "uint32",
                    id: 1
                  },
                  gameConfig: {
                    type: "NumConfig",
                    id: 2
                  }
                }
              },
              PlayerBetInfo: {
                fields: {
                  leftTime: {
                    type: "uint32",
                    id: 1
                  },
                  betUser: {
                    type: "uint32",
                    id: 2
                  },
                  betAmount: {
                    type: "uint64",
                    id: 3
                  }
                }
              },
              PlayerWaitAward: {
                fields: {
                  time: {
                    type: "uint32",
                    id: 1
                  },
                  leftTime: {
                    type: "uint32",
                    id: 2
                  }
                }
              },
              PlayerAwardPrize: {
                fields: {
                  uid: {
                    type: "uint64",
                    id: 1
                  },
                  ownWinInfo: {
                    type: "WinInfo",
                    id: 2
                  },
                  bets: {
                    rule: "repeated",
                    type: "BetElement",
                    id: 3
                  },
                  allUser: {
                    type: "uint32",
                    id: 4
                  },
                  allBonus: {
                    type: "uint64",
                    id: 5
                  },
                  time: {
                    type: "uint32",
                    id: 6
                  },
                  leftTime: {
                    type: "uint32",
                    id: 7
                  },
                  result: {
                    type: "NumResult",
                    id: 8
                  },
                  serverStatus: {
                    type: "uint32",
                    id: 9
                  },
                  statistics: {
                    type: "string",
                    id: 10
                  },
                  orderNo: {
                    type: "string",
                    id: 11
                  }
                }
              },
              EnterGameData: {
                fields: {
                  gameStatus: {
                    type: "uint32",
                    id: 1
                  },
                  gameConfig: {
                    type: "NumConfig",
                    id: 2
                  },
                  betInfo: {
                    type: "PlayerBetInfo",
                    id: 3
                  },
                  waitAward: {
                    type: "PlayerWaitAward",
                    id: 4
                  },
                  awardPrize: {
                    type: "PlayerAwardPrize",
                    id: 5
                  },
                  bets: {
                    rule: "repeated",
                    type: "BetElement",
                    id: 6
                  },
                  betUser: {
                    type: "uint32",
                    id: 7
                  },
                  betAmount: {
                    type: "uint64",
                    id: 8
                  },
                  statistics: {
                    type: "string",
                    id: 9
                  },
                  orderNo: {
                    type: "string",
                    id: 10
                  }
                }
              }
            }
          },
          BetArea: {
            values: {
              kBetBig: 0,
              kBetSmall: 1,
              kBetPanther: 2
            }
          },
          SicSelector: {
            values: {
              kBeginBet: 256,
              kWaitAward: 257,
              kAwardPrize: 258,
              kApplyAward: 259,
              kPlayerBet: 260,
              kUpdateUsrNum: 261
            }
          },
          SicTableStatus: {
            values: {
              kUnknown: 0,
              kSicUnready: 16,
              kSicBet: 17,
              kSicAnimation: 18,
              kSicWaitAward: 19,
              kSicAward: 20
            }
          },
          SicConfig: {
            fields: {
              config: {
                rule: "repeated",
                type: "SicInfo",
                id: 1
              }
            }
          },
          SicInfo: {
            fields: {
              betId: {
                type: "uint32",
                id: 1
              },
              odds: {
                type: "uint32",
                id: 2
              }
            }
          },
          UpdateRoomUsrNumBrd: {
            fields: {
              usrNum: {
                type: "uint32",
                id: 1
              }
            }
          },
          PlayerBetBrd: {
            fields: {
              uid: {
                type: "uint64",
                id: 1
              },
              usrBet: {
                type: "uint64",
                id: 2
              },
              betId: {
                type: "uint32",
                id: 3
              },
              betAreaSum: {
                type: "uint64",
                id: 4
              },
              ownBetSum: {
                type: "uint64",
                id: 5
              }
            }
          },
          DiceResult: {
            fields: {
              diceOne: {
                type: "uint32",
                id: 1
              },
              diceTwo: {
                type: "uint32",
                id: 2
              },
              diceThree: {
                type: "uint32",
                id: 3
              },
              bonusArea: {
                rule: "repeated",
                type: "uint32",
                id: 4,
                options: {
                  packed: false
                }
              }
            }
          },
          EnterGameData: {
            fields: {
              tableStatus: {
                type: "uint32",
                id: 1
              },
              ownBet: {
                rule: "repeated",
                type: "BetElement",
                id: 2
              },
              allUsrBet: {
                rule: "repeated",
                type: "BetElement",
                id: 3
              },
              topFive: {
                rule: "repeated",
                type: "GameUserInfo",
                id: 4
              },
              history: {
                rule: "repeated",
                type: "DiceResult",
                id: 5
              },
              recommendIndex: {
                type: "uint32",
                id: 6
              },
              leftTime: {
                type: "uint32",
                id: 7
              },
              currentUsrNum: {
                type: "uint32",
                id: 8
              },
              maxBetUsr: {
                type: "GameUserInfo",
                id: 9
              },
              maxBetNum: {
                type: "uint64",
                id: 10
              },
              showPersonInfoOpen: {
                type: "bool",
                id: 11
              }
            }
          },
          UserWinInfo: {
            fields: {
              winId: {
                type: "uint32",
                id: 1
              },
              winBonus: {
                type: "uint32",
                id: 2
              }
            }
          },
          TopFiveWinInfo: {
            fields: {
              uid: {
                type: "uint64",
                id: 1
              },
              totalBonus: {
                type: "uint64",
                id: 2
              },
              winInfo: {
                rule: "repeated",
                type: "UserWinInfo",
                id: 3
              }
            }
          },
          AwardPrizeBrd: {
            fields: {
              awardTime: {
                type: "uint32",
                id: 1
              },
              readyTime: {
                type: "uint32",
                id: 2
              },
              myWinInfo: {
                rule: "repeated",
                type: "UserWinInfo",
                id: 3
              },
              otherWinInfo: {
                rule: "repeated",
                type: "UserWinInfo",
                id: 4
              },
              myBonus: {
                type: "uint64",
                id: 5
              },
              otherBonus: {
                type: "uint64",
                id: 6
              },
              latestBalance: {
                type: "uint64",
                id: 7
              },
              result: {
                type: "DiceResult",
                id: 8
              },
              lastTopFive: {
                rule: "repeated",
                type: "TopFiveWinInfo",
                id: 9
              },
              topFive: {
                rule: "repeated",
                type: "GameUserInfo",
                id: 10
              },
              serverStatus: {
                type: "uint32",
                id: 11
              }
            }
          },
          NotifyWaitAwardBrd: {
            fields: {
              maxBetUsr: {
                type: "GameUserInfo",
                id: 1
              },
              waitTime: {
                type: "uint32",
                id: 2
              },
              betSum: {
                type: "uint64",
                id: 3
              },
              myBetSum: {
                type: "uint64",
                id: 4
              }
            }
          },
          BeginBetBrd: {
            fields: {
              betTime: {
                type: "uint32",
                id: 1
              },
              sicAniTime: {
                type: "uint32",
                id: 2
              }
            }
          }
        }
      },
      msg: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbMessage"
        },
        nested: {
          Relation: {
            values: {
              kNormal: 0,
              kFollowHim: 1,
              kBlock: 2,
              kFriend: 3,
              kMyFans: 4
            }
          },
          TalkType: {
            values: {
              kC2CTalk: 1,
              kC2GTalk: 2
            }
          },
          MsgType: {
            values: {
              MsgTypeUnknown: 0,
              MsgTypePicFile: 1,
              MsgTypeVoice: 2,
              MsgTypeDocFile: 3,
              MsgTypeVideoFile: 4,
              MsgTypeLocate: 5,
              MsgTypeUserCard: 6,
              MsgTypeAppCard: 7,
              MsgTypePrivacyPicFile: 8,
              MsgTypeViewPrivacyPicFile: 9,
              MsgTypeSys: 10,
              MsgTypePlainText: 12,
              MsgTypeSticker: 13,
              MsgTypeGuidence: 14,
              MsgTypeQuestion: 15,
              MsgTypeLike: 19,
              MsgTypeTypingText: 20,
              MsgTypeCallVoiceStart: 21,
              MsgTypeCallVideoStart: 22,
              MsgTypeCallDecline: 25,
              MsgTypeCallCancel: 26,
              MsgTypeCallEnd: 27,
              MsgTypeShareFeed: 52,
              MsgTypeShareUser: 53,
              MsgTypeSayHi: 54,
              MsgTypeGift: 59,
              MsgTypeViewProfile: 60,
              MsgTypeVideoPreload: 61,
              MsgTypeGiftRequest: 62,
              MsgTypeInviteEnterSeat: 70,
              MsgTypeInviteEnterRoom: 71,
              MsgTypeLiveGiftInfo: 72,
              MsgAddFriend: 73,
              MsgAgreeFriend: 74,
              MsgTypePassthrough: 10000,
              MsgLiveMatchPcNotify: 2000,
              MsgLiveMatchPcSuccess: 2001,
              MsgLiveSuperToFriendWaiting: 2002
            }
          },
          MsgStatus: {
            values: {
              kMsgStatusSendSuccess: 1,
              kMsgStatusSendFailed: 2,
              kMsgStatusSending: 16,
              kMsgStatusRecvUnread: 24,
              kMsgStatusRecvReaded: 25,
              kMsgStatusRecvScanned: 32
            }
          },
          Msg: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              relation: {
                type: "uint32",
                id: 3
              },
              seq: {
                type: "fixed64",
                id: 4
              },
              msgId: {
                type: "string",
                id: 5
              },
              timestamp: {
                type: "fixed64",
                id: 6
              },
              talkType: {
                type: "uint32",
                id: 7
              },
              fromNickname: {
                type: "string",
                id: 8
              },
              avatar: {
                type: "string",
                id: 9
              },
              isVip: {
                type: "bool",
                id: 10
              },
              isOfficial: {
                type: "bool",
                id: 11
              },
              ttl: {
                type: "uint32",
                id: 12
              },
              contentType: {
                type: "uint32",
                id: 13
              },
              content: {
                type: "bytes",
                id: 14
              },
              senderInfo: {
                type: "SenderInfo",
                id: 15
              },
              fromUsertype: {
                type: "uint32",
                id: 16,
                options: {
                  "default": 0
                }
              },
              toUsertype: {
                type: "uint32",
                id: 17,
                options: {
                  "default": 0
                }
              },
              typing: {
                type: "bool",
                id: 18,
                options: {
                  "default": false
                }
              },
              typingTime: {
                type: "uint32",
                id: 19,
                options: {
                  "default": 0
                }
              },
              lang: {
                type: "string",
                id: 20,
                options: {
                  "default": "en"
                }
              },
              live: {
                type: "bool",
                id: 21,
                options: {
                  "default": false
                }
              },
              streamId: {
                type: "uint32",
                id: 22,
                options: {
                  "default": 0
                }
              },
              secret: {
                type: "bool",
                id: 23
              },
              recharge: {
                type: "int32",
                id: 24,
                options: {
                  "default": 0
                }
              },
              priceType: {
                type: "int32",
                id: 25,
                options: {
                  "default": 0
                }
              },
              userInfo: {
                type: "UserInfo",
                id: 26
              },
              zhuboInfo: {
                type: "UserInfo",
                id: 27
              },
              isFriend: {
                type: "bool",
                id: 28
              }
            }
          },
          S2CMsgRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              fromUin: {
                type: "fixed64",
                id: 2
              },
              toUin: {
                type: "fixed64",
                id: 3
              },
              seq: {
                type: "fixed64",
                id: 4
              },
              msgId: {
                type: "string",
                id: 5
              },
              timestamp: {
                type: "fixed64",
                id: 6
              },
              contentType: {
                type: "uint32",
                id: 7
              }
            }
          },
          C2SMsgStatusReport: {
            fields: {
              statusList: {
                rule: "repeated",
                type: "SingleMsgStatus",
                id: 1
              }
            }
          },
          ChatMessageList: {
            fields: {
              msgList: {
                rule: "repeated",
                type: "ChatMessage",
                id: 1
              }
            }
          },
          HistoryMessageList: {
            fields: {
              msgList: {
                rule: "repeated",
                type: "Msg",
                id: 1
              },
              zhuboid: {
                type: "fixed64",
                id: 2
              },
              userid: {
                type: "fixed64",
                id: 3
              }
            }
          },
          UserInfo: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              gender: {
                type: "uint32",
                id: 2
              },
              country: {
                type: "string",
                id: 3
              },
              avatar: {
                type: "string",
                id: 4
              },
              age: {
                type: "uint32",
                id: 5
              },
              language: {
                type: "string",
                id: 6
              },
              displayName: {
                type: "string",
                id: 7
              },
              onlineStatus: {
                type: "uint32",
                id: 8
              },
              signature: {
                type: "string",
                id: 9
              }
            }
          },
          ChatMessage: {
            fields: {
              userid: {
                type: "fixed64",
                id: 1
              },
              kefuid: {
                type: "fixed64",
                id: 2
              },
              zhuboid: {
                type: "fixed64",
                id: 3
              },
              msg: {
                type: "Msg",
                id: 4
              },
              userInfo: {
                type: "UserInfo",
                id: 5
              },
              zhuboInfo: {
                type: "UserInfo",
                id: 6
              },
              msgtime: {
                type: "fixed64",
                id: 7
              },
              isFriend: {
                type: "bool",
                id: 8
              }
            }
          },
          UserImageRsp: {
            fields: {
              images: {
                type: "string",
                id: 1
              }
            }
          },
          OnlineStatusReq: {
            fields: {
              uids: {
                type: "string",
                id: 1
              }
            }
          },
          OnlineStatus: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              status: {
                type: "uint32",
                id: 2
              }
            }
          },
          OnlineStatusList: {
            fields: {
              status: {
                rule: "repeated",
                type: "OnlineStatus",
                id: 1
              }
            }
          },
          SingleMsgStatus: {
            fields: {
              uin: {
                type: "fixed64",
                id: 1
              },
              status: {
                type: "uint32",
                id: 2
              },
              talkType: {
                type: "int32",
                id: 3,
                options: {
                  "default": 1
                }
              },
              chatUin: {
                type: "fixed64",
                id: 4
              },
              seq: {
                type: "fixed64",
                id: 5
              },
              msgId: {
                type: "string",
                id: 6
              }
            }
          },
          C2SOfflineMsgStatusReq: {
            fields: {
              chatUinList: {
                rule: "repeated",
                type: "fixed64",
                id: 1,
                options: {
                  packed: false
                }
              }
            }
          },
          S2CMsgStatusChangeNotify: {
            fields: {
              statusList: {
                rule: "repeated",
                type: "S2CMsgStatus",
                id: 1
              }
            }
          },
          S2CMsgStatus: {
            fields: {
              chatUin: {
                type: "fixed64",
                id: 1
              },
              readedSeq: {
                type: "fixed64",
                id: 2
              },
              recvUnreadSeq: {
                type: "fixed64",
                id: 3
              }
            }
          },
          VideoPreloadInfo: {
            fields: {
              uin: {
                type: "fixed64",
                id: 1
              },
              videoUrl: {
                type: "string",
                id: 2
              }
            }
          },
          LikeYouInfo: {
            fields: {
              uin: {
                type: "fixed64",
                id: 1
              },
              fromNickname: {
                type: "string",
                id: 2
              },
              avatar: {
                type: "string",
                id: 3
              }
            }
          },
          ViewProfileInfo: {
            fields: {
              dailyViewCount: {
                type: "int32",
                id: 1
              },
              totalViewCount: {
                type: "int32",
                id: 2
              }
            }
          },
          MediaCallType: {
            values: {
              VoiceCallType: 1,
              VideoCallType: 2
            }
          },
          MediaCallInfo: {
            fields: {
              type: {
                type: "uint32",
                id: 1
              },
              duration: {
                type: "uint32",
                id: 2
              },
              roomId: {
                type: "string",
                id: 3
              },
              exception: {
                type: "uint32",
                id: 4,
                options: {
                  "default": 0
                }
              },
              source: {
                type: "uint32",
                id: 5
              }
            }
          },
          GuidenceInfo: {
            fields: {
              image: {
                type: "string",
                id: 1
              },
              text: {
                type: "string",
                id: 2
              },
              deepLink: {
                type: "string",
                id: 3
              },
              type: {
                type: "uint32",
                id: 4
              },
              extra: {
                keyType: "string",
                type: "string",
                id: 5
              },
              templateId: {
                type: "fixed64",
                id: 6
              },
              style: {
                type: "uint32",
                id: 7
              },
              title: {
                type: "string",
                id: 8
              }
            }
          },
          QuestionInfo: {
            fields: {
              questionId: {
                rule: "required",
                type: "string",
                id: 1
              },
              image: {
                type: "string",
                id: 2
              },
              text: {
                type: "string",
                id: 3
              },
              answer: {
                rule: "repeated",
                type: "AnswerInfo",
                id: 4
              },
              scriptMessageId: {
                type: "string",
                id: 5
              }
            }
          },
          AnswerInfo: {
            fields: {
              answerId: {
                type: "string",
                id: 1
              },
              text: {
                type: "string",
                id: 2
              }
            }
          },
          Text: {
            fields: {
              content: {
                type: "bytes",
                id: 1
              },
              translateOriginContent: {
                type: "string",
                id: 2
              },
              autoTranslate: {
                type: "uint32",
                id: 3,
                options: {
                  "default": 1
                }
              },
              atUserList: {
                rule: "repeated",
                type: "AtUserInfo",
                id: 4
              }
            }
          },
          TypingText: {
            fields: {
              content: {
                type: "string",
                id: 1
              },
              translateOriginContent: {
                type: "string",
                id: 2
              },
              typingTime: {
                type: "int32",
                id: 3
              }
            }
          },
          PicType: {
            values: {
              kPicTypeNormal: 0,
              kPicTypeGif: 1
            }
          },
          Picture: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              md5: {
                type: "bytes",
                id: 4
              },
              size: {
                type: "uint32",
                id: 5
              },
              heigh: {
                type: "uint32",
                id: 6
              },
              width: {
                type: "uint32",
                id: 7
              }
            }
          },
          PrivacyPicture: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              md5: {
                type: "bytes",
                id: 4
              },
              size: {
                type: "uint32",
                id: 5
              },
              heigh: {
                type: "uint32",
                id: 6
              },
              width: {
                type: "uint32",
                id: 7
              },
              diamond: {
                type: "uint32",
                id: 8
              },
              expire: {
                type: "uint32",
                id: 9
              },
              status: {
                type: "uint32",
                id: 10
              }
            }
          },
          Sticker: {
            fields: {
              packId: {
                type: "string",
                id: 1
              },
              sid: {
                type: "string",
                id: 2
              },
              fid: {
                type: "string",
                id: 3
              },
              type: {
                type: "uint32",
                id: 4
              },
              extendType: {
                type: "uint32",
                id: 5
              }
            }
          },
          Voice: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              duration: {
                type: "uint32",
                id: 4
              },
              size: {
                type: "uint64",
                id: 5
              }
            }
          },
          Video: {
            fields: {
              fid: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              type: {
                type: "uint32",
                id: 3
              },
              md5: {
                type: "bytes",
                id: 4
              },
              thumbFid: {
                type: "string",
                id: 5
              },
              duration: {
                type: "uint32",
                id: 6
              },
              size: {
                type: "uint64",
                id: 7
              },
              heigh: {
                type: "uint32",
                id: 8
              },
              width: {
                type: "uint32",
                id: 9
              }
            }
          },
          GiftScene: {
            values: {
              normal: 0,
              mediacall: 1,
              request: 2,
              live: 3
            }
          },
          GiftInfo: {
            fields: {
              type: {
                type: "uint32",
                id: 1
              },
              diamond: {
                type: "uint32",
                id: 2
              },
              giftId: {
                type: "string",
                id: 3
              },
              giftType: {
                type: "uint32",
                id: 4
              },
              name: {
                type: "string",
                id: 5
              },
              image: {
                type: "string",
                id: 6
              },
              effect: {
                type: "string",
                id: 7
              },
              scene: {
                type: "uint32",
                id: 8,
                options: {
                  "default": 0
                }
              },
              voice: {
                type: "bool",
                id: 9
              },
              kind: {
                type: "uint32",
                id: 10
              }
            }
          },
          GiftRequest: {
            fields: {
              type: {
                type: "uint32",
                id: 1
              },
              diamond: {
                type: "uint32",
                id: 2
              },
              giftId: {
                type: "string",
                id: 3
              },
              giftType: {
                type: "uint32",
                id: 4
              },
              name: {
                type: "string",
                id: 5
              },
              image: {
                type: "string",
                id: 6
              },
              effect: {
                type: "string",
                id: 7
              },
              scene: {
                type: "uint32",
                id: 8,
                options: {
                  "default": 2
                }
              }
            }
          },
          SenderInfo: {
            fields: {
              lat: {
                type: "float",
                id: 1
              },
              lng: {
                type: "float",
                id: 2
              },
              level: {
                type: "uint32",
                id: 3
              },
              gender: {
                type: "uint32",
                id: 4
              },
              age: {
                type: "string",
                id: 5
              },
              country: {
                type: "string",
                id: 6
              },
              countryIcon: {
                type: "string",
                id: 7
              },
              timezone: {
                type: "string",
                id: 8
              },
              language: {
                type: "string",
                id: 9
              }
            }
          },
          Vip: {
            fields: {
              text: {
                type: "string",
                id: 1
              },
              productName: {
                type: "string",
                id: 2
              }
            }
          },
          FollowMeInfo: {
            fields: {
              text: {
                type: "string",
                id: 1
              },
              followMeType: {
                type: "int32",
                id: 2
              }
            }
          },
          ShareFeedInfo: {
            fields: {
              ownerUin: {
                type: "uint64",
                id: 1
              },
              ownerName: {
                type: "string",
                id: 2
              },
              ownerAvatar: {
                type: "string",
                id: 3
              },
              feedId: {
                type: "string",
                id: 4
              },
              image: {
                type: "string",
                id: 5
              },
              content: {
                type: "string",
                id: 6
              },
              feedType: {
                type: "uint32",
                id: 7
              },
              feedRealType: {
                type: "uint32",
                id: 8
              },
              videoTime: {
                type: "uint64",
                id: 9
              }
            }
          },
          ShareUserInfo: {
            fields: {
              uid: {
                type: "uint64",
                id: 1
              },
              nick: {
                type: "string",
                id: 2
              },
              userDescription: {
                type: "string",
                id: 3
              },
              avatar: {
                type: "string",
                id: 4
              },
              gendar: {
                type: "uint32",
                id: 5
              },
              birthday: {
                type: "uint64",
                id: 6
              },
              flag: {
                type: "string",
                id: 7
              },
              lat: {
                type: "float",
                id: 8
              },
              lng: {
                type: "float",
                id: 9
              }
            }
          },
          LocationInfo: {
            fields: {
              latitude: {
                type: "double",
                id: 1
              },
              longitude: {
                type: "double",
                id: 2
              },
              locationTitle: {
                type: "string",
                id: 3
              },
              locationDesc: {
                type: "string",
                id: 4
              }
            }
          },
          AtUserInfo: {
            fields: {
              uid: {
                type: "uint64",
                id: 1
              },
              nickname: {
                type: "string",
                id: 2
              }
            }
          },
          RecentOnlineUserReq: {
            fields: {
              lang: {
                type: "string",
                id: 1
              }
            }
          },
          RecentOnlineUserRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              users: {
                rule: "repeated",
                type: "RecentOnlineUser",
                id: 2
              }
            }
          },
          RecentOnlineUser: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              avatarFid: {
                type: "string",
                id: 2
              },
              loginTime: {
                type: "fixed64",
                id: 3
              }
            }
          },
          TagRecoUserEntity: {
            fields: {
              text: {
                type: "string",
                id: 1
              },
              reason: {
                type: "string",
                id: 2
              }
            }
          },
          TagRecoUserEntityReply: {
            fields: {
              text: {
                type: "string",
                id: 1
              },
              reason: {
                type: "string",
                id: 2
              },
              replyText: {
                type: "string",
                id: 3
              }
            }
          },
          MsgBroadcast: {
            fields: {
              seq: {
                type: "uint64",
                id: 1
              },
              originCmd: {
                type: "uint32",
                id: 2
              },
              msg: {
                type: "Msg",
                id: 3
              },
              country: {
                type: "string",
                id: 4
              }
            }
          },
          MsgTypeTypingReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              }
            }
          },
          MsgTypeRecallReq: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              msgId: {
                type: "string",
                id: 3
              }
            }
          },
          MsgTypeRecallRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              msgId: {
                type: "string",
                id: 2
              }
            }
          },
          MsgTypeRecallNotify: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              toUin: {
                type: "fixed64",
                id: 2
              },
              msgId: {
                type: "string",
                id: 3
              }
            }
          },
          MsgTypeRecallAck: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              msgId: {
                type: "string",
                id: 2
              }
            }
          },
          AddFriend: {
            fields: {
              content: {
                type: "string",
                id: 1
              },
              style: {
                type: "uint32",
                id: 2
              },
              scene: {
                type: "uint32",
                id: 3
              }
            }
          },
          FaceDiscoverNotify: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              roomId: {
                type: "string",
                id: 2
              },
              faceDiscover: {
                type: "bool",
                id: 3
              }
            }
          },
          NewChatNotify: {
            fields: {
              zhuboid: {
                type: "fixed64",
                id: 1
              },
              userid: {
                type: "fixed64",
                id: 2
              },
              kefuid: {
                type: "fixed64",
                id: 3
              }
            }
          }
        }
      },
      offline: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbOffline"
        },
        nested: {
          Conversation: {
            fields: {
              chatUin: {
                type: "fixed64",
                id: 1
              },
              talkType: {
                type: "int32",
                id: 2
              },
              total: {
                type: "uint32",
                id: 3
              },
              maxSeq: {
                type: "fixed64",
                id: 4
              },
              minSeq: {
                type: "fixed64",
                id: 5
              },
              isFriend: {
                type: "bool",
                id: 8
              }
            }
          },
          C2SConversationReq: {
            fields: {
              uin: {
                type: "fixed64",
                id: 1
              },
              pageNum: {
                type: "uint32",
                id: 2
              },
              pageSize: {
                type: "uint32",
                id: 3
              }
            }
          },
          S2CConversationRsp: {
            fields: {
              rspHead: {
                type: "common.RspHead",
                id: 1
              },
              convList: {
                rule: "repeated",
                type: "Conversation",
                id: 2
              }
            }
          },
          C2SOfflineMsgReq: {
            fields: {
              talkType: {
                type: "int32",
                id: 1,
                options: {
                  "default": 1
                }
              },
              uin: {
                type: "fixed64",
                id: 2
              },
              chatUin: {
                type: "fixed64",
                id: 3
              },
              count: {
                type: "uint32",
                id: 4
              },
              curSeq: {
                type: "fixed64",
                id: 5
              },
              direction: {
                type: "uint32",
                id: 6
              }
            }
          },
          S2COfflineMsgRsp: {
            fields: {
              talkType: {
                type: "int32",
                id: 1
              },
              uin: {
                type: "fixed64",
                id: 2
              },
              chatUin: {
                type: "fixed64",
                id: 3
              },
              count: {
                type: "uint32",
                id: 4
              },
              curSeq: {
                type: "fixed64",
                id: 5
              },
              direction: {
                type: "uint32",
                id: 6
              },
              msgList: {
                rule: "repeated",
                type: "msg.Msg",
                id: 7
              }
            }
          }
        }
      },
      online_status: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbOnlineStatus"
        },
        nested: {
          SubOnlineStatusType: {
            values: {
              RENEW: 1,
              RESTART: 2
            }
          },
          SubUserOnlineStatusReq: {
            fields: {
              uidList: {
                rule: "repeated",
                type: "fixed64",
                id: 1,
                options: {
                  packed: false
                }
              },
              type: {
                type: "int32",
                id: 2
              },
              askStatus: {
                type: "int32",
                id: 3
              }
            }
          },
          SubUserOnlineStatusRsp: {
            fields: {
              uidList: {
                rule: "repeated",
                type: "UserOnlineStatus",
                id: 1
              }
            }
          },
          CancelSubUserOnlineStatusReq: {
            fields: {
              uidList: {
                rule: "repeated",
                type: "fixed64",
                id: 1,
                options: {
                  packed: false
                }
              }
            }
          },
          CancelSubUserOnlineStatusRsp: {
            fields: {
              uidList: {
                rule: "repeated",
                type: "fixed64",
                id: 1,
                options: {
                  packed: false
                }
              }
            }
          },
          UserOnlineStatusChangeNotify: {
            fields: {
              onlineStatusList: {
                rule: "repeated",
                type: "UserOnlineStatus",
                id: 1
              }
            }
          },
          UserOnlineStatus: {
            fields: {
              uid: {
                type: "fixed64",
                id: 1
              },
              onlineStatus: {
                type: "int32",
                id: 2
              }
            }
          }
        }
      },
      payment: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbPayment"
        },
        nested: {
          PaymentChannel: {
            values: {
              GOOGLE: 0,
              APPLE: 1,
              PAYPAL: 2,
              DOKYPAY: 3,
              CODAPAY: 4,
              SHAREIT: 5,
              TAPPAY: 6,
              GPAY: 7,
              PAYSSION: 8
            }
          },
          ProductType: {
            values: {
              IN_BUY: 1,
              SUBSCRIPTION: 2
            }
          },
          Payment: {
            fields: {
              fromUin: {
                type: "fixed64",
                id: 1
              },
              payChannel: {
                type: "uint32",
                id: 3
              },
              productId: {
                type: "string",
                id: 4
              },
              productType: {
                type: "uint32",
                id: 5
              },
              productCurrency: {
                type: "string",
                id: 6
              },
              orderId: {
                type: "string",
                id: 7
              },
              amount: {
                type: "double",
                id: 8
              },
              lvpt: {
                rule: "repeated",
                type: "Lvpt",
                id: 9
              }
            }
          },
          Lvpt: {
            fields: {
              eventName: {
                type: "string",
                id: 1
              },
              value: {
                type: "double",
                id: 2
              }
            }
          }
        }
      },
      packet: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbRedpacket"
        },
        nested: {
          S2CRedpacketSendNty: {
            fields: {
              roomId: {
                type: "string",
                id: 1
              },
              roomType: {
                type: "uint32",
                id: 2
              },
              orderId: {
                type: "uint32",
                id: 3
              },
              type: {
                type: "uint32",
                id: 4
              },
              amount: {
                type: "uint32",
                id: 5
              },
              total: {
                type: "uint32",
                id: 6,
                options: {
                  "default": 1
                }
              },
              userInfo: {
                type: "common.UserInfo",
                id: 7
              }
            }
          },
          S2CRedpacketGrabNty: {
            fields: {
              roomId: {
                type: "string",
                id: 1
              },
              roomType: {
                type: "uint32",
                id: 2
              },
              redpacketId: {
                type: "uint32",
                id: 3
              },
              redpacketType: {
                type: "uint32",
                id: 4
              },
              amount: {
                type: "uint32",
                id: 5
              },
              orderId: {
                type: "uint32",
                id: 6
              },
              userInfo: {
                type: "common.UserInfo",
                id: 7
              }
            }
          }
        }
      },
      mq_delivery: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbMQDelivery"
        },
        nested: {
          UserAction: {
            values: {
              UNKNOW: 0,
              SAYHI: 1,
              LIKE: 2,
              REGISTER: 3,
              LOGIN: 4,
              SUBSCRIPTION: 5,
              BUYDIAMOND: 6,
              OPENAPP: 7,
              AUDIOCALL: 8,
              VIDEOCALL: 9,
              QUESTION: 10,
              ViewProfile: 11
            }
          },
          APIReportInfo: {
            fields: {
              fromUserId: {
                type: "fixed64",
                id: 1
              },
              toUserId: {
                type: "fixed64",
                id: 2
              },
              action: {
                type: "uint32",
                id: 3
              },
              body: {
                type: "string",
                id: 4
              },
              packageType: {
                type: "uint32",
                id: 5
              }
            }
          }
        }
      },
      sys_notify: {
        options: {
          java_package: "com.cloud.im.proto",
          java_outer_classname: "PbSysNotify"
        },
        nested: {
          PassthroughMsgClassify: {
            values: {
              kNewMessage: 160,
              kMediaCall: 170,
              kSystemMessage: 180,
              kInstruction: 181,
              KPayNotify: 182,
              kMatchBlock: 183,
              kSubNotify: 184,
              kGenderChange: 185,
              kRegistered: 186,
              kUserOnline: 187,
              kImagePorn: 188,
              kReportSuccess: 189,
              kReportGuide: 190,
              kViolationWarning: 191,
              kPornWaring: 192,
              kPointObtain: 195,
              kNoActionWorkState: 196,
              kFrontNoAnswerWorkState: 197,
              kBackNoAnswerWorkState: 198,
              kCloseWorkState: 199,
              kLanguageSetting: 200,
              kSimulationCall: 201,
              kAnchorPassAudit: 202,
              kAnchorInformationSubmit: 203,
              kAnchorNotPassAudit: 204,
              kGoldObtain: 205,
              kPopupCheapGoldPackage: 206,
              kPopupGuideAddFriend: 207,
              kPopupHeartBeat: 208,
              kPopupRecommendedOnePerson: 209,
              KPopupSlotMachine: 210,
              kPopupMatch: 211,
              kPopupFieryVideo: 212,
              kPopupRecommendedMultiPerson: 213,
              KPopupSimulationCall: 214
            }
          },
          SingleSysNotify: {
            fields: {
              classify: {
                type: "uint32",
                id: 1
              },
              title: {
                type: "string",
                id: 2
              },
              img: {
                type: "string",
                id: 3
              },
              name: {
                type: "string",
                id: 4
              },
              content: {
                type: "bytes",
                id: 5
              },
              jumpLink: {
                type: "string",
                id: 6
              },
              extra: {
                keyType: "string",
                type: "string",
                id: 7
              },
              templateId: {
                type: "fixed64",
                id: 8
              },
              offlineNotice: {
                type: "bool",
                id: 9,
                options: {
                  "default": true
                }
              }
            }
          },
          S2CSysNotify: {
            fields: {
              seq: {
                type: "fixed64",
                id: 1
              },
              notifyId: {
                type: "string",
                id: 2
              },
              timestamp: {
                type: "fixed64",
                id: 3
              },
              passthrough: {
                type: "bool",
                id: 4,
                options: {
                  "default": false
                }
              },
              notifyList: {
                rule: "repeated",
                type: "SingleSysNotify",
                id: 5
              },
              version: {
                type: "uint32",
                id: 6
              }
            }
          },
          C2SSysNotifyAck: {
            fields: {
              seq: {
                type: "uint32",
                id: 1
              },
              timestamp: {
                type: "fixed64",
                id: 2
              },
              notifyId: {
                type: "string",
                id: 3
              }
            }
          }
        }
      }
    }
  }
});

module.exports = $root;
