import ws from './connect'
import pf from './proto'
import root from '@/json/proto'
import store from '@/store'
import main from './main'
import { bTos, utf8Tos } from '@/utils/tools'

const frame = root.lookupType('proto.frame.Frame')
const msg = root.lookupType('proto.msg.Msg')
const rspText = root.lookupType('proto.msg.Text')

const chatMsgListsRsp = root.lookupType('proto.msg.ChatMessageList')
const chatHistoryMsgRsp = root.lookupType('proto.msg.HistoryMessageList')
const userOnlineRsp = root.lookupType('proto.msg.OnlineStatusList')
const newUserHosterRsp = root.lookupType('proto.msg.NewChatNotify')
const hosterImageListsRsp = root.lookupType('proto.msg.UserImageRsp')

const rspx102 = root.lookupType('proto.handshake.S2CHandshakeRsp')

// emun
const cmd = root.lookupEnum('proto.common.Cmd')
const msgType = root.lookupEnum('proto.msg.MsgType')

function receive(rev) {
  const { isTrusted, data } = rev
  if (isTrusted) {
    const reader = new FileReader()
    reader.readAsArrayBuffer(data)
    reader.onload = () => {
      const buf = new Uint8Array(reader.result)
      const response = frame.decode(buf)
      console.error('Rev:', response.cmd, cmd.valuesById[response.cmd])
      switch (response.cmd) {
        case cmd.values['kHandshakeRsp']:
          response.body = pf.decode(rspx102, response.body)
          // eslint-disable-next-line no-case-declarations
          const { code, desc } = response.body.rspHead
          console.error('object', code, desc)
          if (Number(code) === 0) {
						store.dispatch("login/updateUserStatus", 1)
            ws.heart()
          } else {
            main.logout()
          }
          break

					// system logout
        case cmd.values['kKickout']:
          store.dispatch('login/systemLogout', 2)
          break

        // get chat list
        case cmd.values['kQueryChatListRsp']:
          response.body = pf.decode(chatMsgListsRsp, response.body)
					// eslint-disable-next-line no-case-declarations
					const { msgList } = response.body
					if(msgList&& msgList.length>0) {
						store.dispatch('msg/updateNewMsgLists', msgList)
					} else {
            store.dispatch('status/setLoadingEnd')
          }
          break

        case cmd.values['kSendChatMsgReq']:
          response.body = pf.decode(msg, response.body)
          switch (Number(response.body.contentType)) {
            case msgType.values['MsgTypePlainText']:
              response.body.content = pf.decode(rspText, response.body.content)
              response.body.text = utf8Tos(response.body.content.content)
              store.dispatch('msg/receiveNewsMsg', response.body)
              break
            case msgType.values['MsgTypePicFile']:
              response.body.content = pf.decode(rspText, response.body.content)
              response.body.text = bTos(response.body.content.content)
              store.dispatch('msg/receiveNewsMsg', response.body)
              break
          }
          break

        // send rsp
        case cmd.values['kSendChatMsgRsp']:
          response.body = pf.decode(msg, response.body)
          switch (Number(response.body.contentType)) {
            case msgType.values['MsgTypePlainText']:
              response.body.content = pf.decode(rspText, response.body.content)
              response.body.text = bTos(response.body.content.content)
              store.dispatch('msg/sendChatMsgRsp', response.body)
              break
            case msgType.values['MsgTypePicFile']:
              response.body.content = pf.decode(rspText, response.body.content)
              response.body.text = bTos(response.body.content.content)
              store.dispatch('msg/sendChatMsgRsp', response.body)
              break
          }
          break
        // get user status
        case cmd.values['kQueryOnlineStatusRsp']:
          response.body = pf.decode(userOnlineRsp, response.body)
          store.dispatch('msg/setUserStatus', response.body)
          break

        // msg history lists
        case cmd.values['kQueryHistoryListRsp']:
          response.body = pf.decode(chatHistoryMsgRsp, response.body)
          store.dispatch('msg/historyMsgLists', response)
          break

        // new user and hoster
        case cmd.values['kNewChatNotify']:
          response.body = pf.decode(newUserHosterRsp, response.body)
          store.dispatch('msg/newUserHosterLists', response)
          break

        // get hoster image lists 
        case cmd.values['kQueryUserImagesRsp']:
          response.body = pf.decode(hosterImageListsRsp, response.body)
          store.dispatch('msg/hosterImageLists', response.body)
          break

        default:
      }
      console.error('#####$$$$$RSP Data: ', response)
    }
  }
}




const cmdim = {
  timeout: 30000,
  timer: null,
  roomid: undefined,
  receive: receive,
}

export default cmdim
