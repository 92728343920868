<template>
<div v-loading="loadingStatus"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="main">
     <yumy-imui
     :client="UserData"
        ref="IMUI"
        @send="sendMessage"
        @change-contact="handleChangeContact"
        @pull-messages="handlePullMessages"
        :width="width"
        :height="height"
        :gutter="gutter"
      >
     </yumy-imui>
  </div>
  <el-dialog title="登录" width="400px" :show-close="false" top="20vh" modal :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogFormVisible">
  <el-form :model="form" ref="formLogin">
    <el-form-item prop="clientId" label="客服 ID:" :label-width="formLabelWidth"
    :rules="[
      { required: true, message: '客服ID不能为空'},
      { type: 'number', message: '客服ID必须为数字值'}
    ]">
      <el-input class="inputWidth" v-model.number="form.clientId" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item  prop="password" label="密 码:" :label-width="formLabelWidth"
    :rules="{
      required: true, message: '密码不能为空', trigger: 'blur'
    }">
      <el-input type="password" class="inputWidth" v-model="form.password" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="resetForm('formLogin')" :loading="btnLoading">重置</el-button>
    <el-button type="primary" @click="submitForm('formLogin')" :loading="btnLoading">确 定</el-button>
  </div>
  </el-dialog>
  <el-dialog title="提示" width="400px" :show-close="false" top="20vh" modal :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogLogout">
    <div>
      <h3>您的账号已经在另外一台设备上登录了,<br />
        请单击确认!</h3>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="reloadWebsite()">确 定</el-button>
    </div>
  </el-dialog>

  <el-dialog title="主播相册" width="80%" :show-close="false" top="20vh" modal :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogImgs">
    <div>
      <el-row :gutter="10" v-if="listsImgs&&listsImgs.length>0">
        <el-col :span="6" v-for="(url, index) in listsImgs" :key="index">
          <el-card :body-style="{ padding: '0px' }">
            <img :src="url" class="image">
            <div style="padding: 14px;text-align:center">
              <div class="bottom clearfix">
                <el-button @click="sendImgs(url)" type="primary" plain class="button">发送图片</el-button>
              </div>
            </div>
          </el-card>
          <div class="heigt10"></div>
        </el-col>
      </el-row>
    
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">关 闭</el-button>
    </div>
  </el-dialog>
  
</div>
</template>

<script>
import websocket from '@/socket/main'
import w2s from  '@/socket/connect'
import cmdim from '@/socket/cmdim'
import { mapGetters } from 'vuex'
import UserData from "@/database/user";
import MessagesData from "@/database/messages";
import EmojiData from "@/database/emojiplus";
import {encodeId, decodeId} from '@/utils/tools'
import store from '@/store';

export default {
  name: 'IndexPage',
  data () {
    return {
      loadingStatus: false,
      formLabelWidth: '100px',
      dialogFormVisible: false,
      dialogLogout: false,
      btnLoading: false,
      dialogImgs: false,
      form: {
        clientId: null, 
        password: null
      },
      UserData,
      MessagesData,
      gutter: 40,
      width: 1024,
      height: 768,
      listsImgs: [],
      sendStatusTimes: 10,
      
      getHistoryTimes: 5
    }
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      'contactsData',
      'webStatus',
      'userStatus',
      'newMsgLists',
      'historyMsg',
      'revNewMsg',
      'sendMsgStatus',
      'userLists',
      'hostImgs',
      'isDialog',
      'loading'

    ])
  },
  watch: {
    webStatus(n, o){
      console.error('watch: webStatus ', n, o)
      this.initState()
    },
    userStatus(n, o) {
      console.error('watch: userStatus ', n, o)
      this.initUserState()
    },
    revNewMsg(n) {
      this.$refs.IMUI.appendContactList(n)
      this.$refs.IMUI.appendMessageLists(n, true)
    },
    historyMsg(n) {
      if(n&&n.length>0) {
        this.MessagesData[n[0].toContactId] = []
        n.map(e => {
          this.MessagesData[e.toContactId].unshift(e)
        })
      }
    },
    sendMsgStatus(n) {
      console.error('1.sendMsgStatus', n);
    },
    hostImgs(n) {
      this.listsImgs = n
    },
    loading(n) {
      this.loadingStatus = n
    },
    isDialog(n) {
      this.dialogImgs = n
    }


  },
  mounted () {
    const IMUI = this.$refs.IMUI;
    this.width = document.documentElement.clientWidth - this.gutter
    this.height = document.documentElement.clientHeight - this.gutter
    IMUI.initEmoji(EmojiData);
    this.initUserState()
    this.initContactsHandle(this.contactsData)
  },
  methods: {
    initContactsHandle(){
      setTimeout(() => {
        this.$refs.IMUI.initContacts(this.contactsData);
      },1000)
    },
    initUserState(){
      switch(Number(this.userStatus)){
        case 0:
          this.dialogFormVisible = true
          break
        case 1:
          this.dialogFormVisible = false
          this.btnLoading = false
          this.getMsgLists()
          break
        case 2:
          w2s.close(7)
          this.dialogLogout = true
          store.dispatch('login/updateClientId', {clientId: null, token: null})
          break
        default:
      }
    },
    initState(){
      if(this.webStatus===1) {
        cmdim.login(this.form)
      } else {
        this.dialogFormVisible = false
        this.btnLoading = true
      }
    },
    findContact(id){
      const data = this.contactsData.find(e => id===e.id)
      return { id: data.id, avatar: data.avatar, displayName: data.displayName };
    },
    unique(item) {
      const ret = this.contactsData.find(e => item.id===e.id)
      if(!ret) {
        this.contactsData.push(item)
      }
    },
    reloadWebsite(){
      store.dispatch('login/updateClientId', { clientId: null, token: null })
      location.reload();
    },
    getMsgLists(){
      cmdim.getMsgLists()
    },
    sendImgs(url){
      this.closeDialog()
      const msg = this.$refs.IMUI.createImgsMessage(url)
      this.$refs.IMUI.sendImgsMsg(msg)
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            websocket.init()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    resetForm(formName) {
        this.$refs[formName].resetFields();
    },
    // eslint-disable-next-line no-unused-vars
    sendMessage(msg, next, file) {
      const type = file || 12
      cmdim.sendMsg(msg, type)
      this.sendStatusTimes = 10
      this.checkSendStatue(msg, next)
    },
    checkSendStatue(msg, next) {
      const cid =  encodeId(msg)
      if(Number(this.sendStatusTimes)>=0) {
        if(this.webStatus===1){
          this.sendStatusTimes = Number(this.sendStatusTimes) - 1
            setTimeout(() => {
              const st = this.sendMsgStatus[cid]
              if(st) {
                next();
                store.dispatch('msg/deleteSendMsgStatus', cid)
                } else {
                this.checkSendStatue(msg, next) 
              }
            }, 1000);
        } else {
          next({status:'failed'})
        }
      } else {
        next({status:'failed'})
      }
    },
    // eslint-disable-next-line no-unused-vars
    handlePullMessages(contact, next, instance) {
      this.getHistoryTimes = 10
      this.updateHistory(contact, next)
    },
    updateHistory(contact, next) {
      if(Number(this.getHistoryTimes)>=0) {
        this.getHistoryTimes = Number(this.getHistoryTimes) - 1
        const msg = this.MessagesData[contact.id]
          setTimeout(() => {
              if(msg) {
                next(msg, true)
              } else {
                this.updateHistory(contact, next)
              }
        }, 500);
      } else {
        const msg = {}
        next(msg[contact.id] = [], true)
      }
    },
    handleChangeContact(contact, instance) {
      cmdim.getHistory(decodeId(contact.id))
      instance.updateContact({
        id: contact.id,
        unread: 0,
      });
      instance.closeDrawer();
    },
    msg(message) {
      this.$message({message})
    },
    closeDialog(){
      store.dispatch('status/setCloseState')
    }

  }
}
</script>
<style scoped>

.main {
  margin: 0 auto;
  padding-top: 20px;
}
.inputWidth {
  width: 240px;
}
.image {
  height: 350px;
  width: auto;
}
.heigt10 {
  height: 10px;
}

</style>