import ws from './connect'
import crypto from 'crypto-js'
import pb from './proto'
import root from '@/json/proto'
import store from '@/store'
import {encodeId, decodeId, sTob} from '@/utils/tools'
import {db} from '@/utils/db'

// const frame = root.lookupType('proto.frame.Frame')
const login_handskake = root.lookupType('proto.handshake.C2SHandshakeReq')
const send_msg = root.lookupType('proto.msg.Msg')
const msg_text = root.lookupType('proto.msg.Text')
const user_status = root.lookupType('proto.msg.OnlineStatusReq')

// emun
const cmd = root.lookupEnum('proto.common.Cmd')
const msgType = root.lookupEnum('proto.msg.MsgType')

function heat(){
	return pb.toFrame(cmd.values['kHeartbeatReport'])
}

function login(item){
  const {clientId, password} = item
  const token = crypto.MD5(password.toString()).toString()
  store.dispatch('login/updateClientId', { clientId, token })
  store.dispatch('status/setLoadingStart')
  sendToSever('kHandshakeReq', login_handskake, { token })
}

function restart(){
  const token = store.state.login.token
  sendToSever('kHandshakeReq', login_handskake, { token })
}

function getMsgLists(){
  const response = pb.toFrame(cmd.values['kQueryChatListReq'])
  ws.send(response)
}

function sendMsg (item, type) {
  let ctype =  msgType.values['MsgTypePlainText']
  if (Number(type)===1) {
    // send msg imgs
    ctype =  msgType.values['MsgTypePicFile']
  }
  const {zhuboInfo, userInfo, zhuboid} = item
  const id = decodeId(item.toContactId)
  const msgTime = new Date().getTime()
  const text = { content: sTob(item.content) }
  const content = pb.encode(msg_text, text)
  const msgItem = {
   contentType : ctype,
   toUsertype : 0,
   fromUsertype : 2,
   fromUin : id.zhuboid,
   toUin : id.userid,
   msgId : msgTime.toString(),
   ...id,
   content,
   zhuboInfo,
   userInfo
  }
  sendToMsg('kSendChatMsgReq', send_msg, msgItem)

  //save msg
  const citem = {
    ...id,
    unread: 0,
    index: `${zhuboInfo.displayName} (${zhuboid})`,
    lastContent: item.content,
    lastSendTime: msgTime,
    userInfo: userInfo,
    zhuboInfo: zhuboInfo,
    isFriend: true
  }
  const svMsg = Object.assign(citem, userInfo)
  svMsg['id'] = encodeId(id)
  db.contacts.put(svMsg)
}


function getHistory({zhuboid, userid}){
  getHosterImage(zhuboid)
  getChatHistory(zhuboid, userid)
}
function getChatHistory(zhuboid, userid){
  const response = pb.toFrame(cmd.values['kQueryHistoryListReq'], {}, Number(userid), Number(zhuboid))
  ws.send(response)
}
function getHosterImage (zhuboid){
  const response = pb.toFrame(cmd.values['kQueryUserImagesReq'], {},  Number(zhuboid), Number(zhuboid))
  ws.send(response)
}

// kQueryOnlineStatusReq
function getUserState() {
  const uids = store.getters.userLists
  let rsp = null
  if(uids&&uids.length>0) {
    const struid = uids.toString()
    const body = pb.encode(user_status, {uids: struid})
    rsp = pb.toFrame(cmd.values['kQueryOnlineStatusReq'], body)
  }
  return rsp
}

// code: cmd code
// type: type in body
// item: josn data in type
function sendToSever(code, type, item){
  const body = pb.encode(type, item)
  const response = pb.toFrame(cmd.values[code], body) 
  ws.send(response)
}

function sendToMsg(code, type, item){
  const b = pb.encode(type, item)
  const response = pb.toFrame(cmd.values[code], b, item.toUin, item.fromUin) 
  ws.send(response)
}




function logout(){}


const cmdim = {
  timeout: 30000,
  timer: null,
  roomid: undefined,
  close,
  heat,
  login,
  logout,
  restart,
  getMsgLists,
  sendMsg,
  getHistory,
  getUserState,
}

export default cmdim
