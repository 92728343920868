import root from '@/json/proto'
import pf from '@/socket/proto'
import {encodeId, utf8Tos} from '@/utils/tools'
import {db} from '@/utils/db'

// const msg = root.lookupType('proto.msg.Msg')
const rspText = root.lookupType('proto.msg.Text')

const state = {
  userLists: [],
  newMsgLists: [],
	msgLists: [],
  revNewMsg: '',
  hMsg:[],
  contactsData: [],
  hostImgs: [],
  MessagesData: {},
  sendMsgStatus: {}
}

const mutations = {
  SET_USERLISTS: (state, uid) => {
    state.userLists.push(uid)
  },
  SET_NEWMSGLISTS: (state, list) => {
    state.newMsgLists.unshift(list)
  },
  SET_CONTACTSLIST_ALL: (state, items) => {
    state.contactsData = items
  },
  SET_CONTACTSLIST: (state, item) => {
    state.contactsData.push(item)
  },
  SET_REVNEWMSGLISTS: (state, lists) => {
    state.revNewMsg = lists 
  },
  SET_HISTORMSG: (state, msg) =>  {
    state.hMsg = msg
  },
  SET_SENDMSGSTATUS: (state, id) => {
    state.sendMsgStatus[id] = 1
  },
  DEL_SENDMSGATATUS: (state, id) => {
    delete state.sendMsgStatus[id]
  },
  SET_HOSTIMAGE: (state, lists) => {
    state.hostImgs = lists
  }

}

const actions = {
  async initConstactsLists({commit}){
    const items = await db.contacts.where('id').startsWithIgnoreCase('').toArray()
    commit('SET_CONTACTSLIST_ALL', items)
  },
  async updateNewMsgLists({commit, dispatch}, lists){
		if( lists &&lists.length>0) {
			lists.forEach( e => {
        e.msg.content = pf.decode(rspText, e.msg.content)
        e.msg.lasText = utf8Tos(e.msg.content.content)
        const id = encodeId(e)
        const isFind = state.contactsData.find(old => old.id===id)
        if(!isFind) {
          const citem = {
            id,
            unread: 0,
            index: `${e.zhuboInfo.displayName} (${e.zhuboid})`,
            lastContent: e.msg.lasText,
            lastSendTime: e.msgtime,
            userInfo: e.userInfo,
            zhuboInfo: e.zhuboInfo,
            kefuid: e.kefuid,
            userid: e.userid,
            zhuboid: e.zhuboid,
            isFriend: e.isFriend
          }
          const item = Object.assign(citem, e.userInfo)
          db.contacts.put(item)
          commit('SET_CONTACTSLIST', item)
        }
      })
		}
    dispatch('status/setLoadingEnd', {}, {root: true})
	},
  receiveNewsMsg({commit, rootGetters}, revNewMsg) {
    const { msgId, fromNickname, fromUin, timestamp, text, toUin, avatar, contentType, userInfo, zhuboInfo, isFriend } = revNewMsg
    let ret = 'text'
    if(Number(contentType) === 1) {
      ret = 'image'
    }
    const tmp = {
      kefuid: rootGetters.clientId,
      zhuboid: toUin,
      userid: fromUin 
    }
    const msg = {
      id: msgId,
      status: "succeed",
      type: ret,
      sendTime: timestamp,
      toContactId: encodeId(tmp),
      content: text,
      fromUser: {
        id: fromUin,
        avatar: avatar, 
        displayName: fromNickname
      },
      userInfo,
      zhuboInfo,
      ...tmp
    }
    commit('SET_REVNEWMSGLISTS', msg)

    //save msg
    const id = encodeId(tmp)
    const citem = {
      id,
      unread: 0,
      index: `${zhuboInfo.displayName} (${tmp.zhuboid})`,
      lastContent: text,
      lastSendTime: timestamp,
      userInfo: userInfo,
      zhuboInfo: zhuboInfo,
      ...tmp,
      isFriend: isFriend
    }
    const item = Object.assign(citem, userInfo)
    db.contacts.put(item)
	},
  initToMsglist({commit}, initMsg){
    const { displayName, kefuid, userid, zhuboid, avatar, userInfo,
      lastSendTime,
      lastContent,
      zhuboInfo  } = initMsg
    const tmp = {
      kefuid,
      zhuboid,
      userid
    }
    const msg = {
      id: encodeId(tmp),
      status: "succeed",
      type: "text",
      sendTime: 1566047865417,
      toContactId: encodeId(tmp),
      lastSendTime,
      content: lastContent,
      unread: 0,
      fromUser: {
        id: userid,
        avatar: avatar, 
        displayName
      },
      userInfo,
      zhuboInfo,
      ...tmp
    }
    commit('SET_REVNEWMSGLISTS', msg)
  },
  historyMsgLists({commit, rootGetters}, hMsg){
		const { body } = hMsg
		const {msgList, userid, zhuboid} = body

    const ret = []
    
    if(msgList&&msgList.length>0) {
			msgList.forEach( e => {
				const {
          content,
          contentType,
          fromUin,
          msgId,
          timestamp,
          userInfo, 
          zhuboInfo,
        } = e
        let msgTypt = 'text'
        if(Number(contentType) === 1) {
          msgTypt = 'image'
        }
        const msgText = pf.decode(rspText, content)
        const text = utf8Tos(msgText.content)
        const idsrc = {
          kefuid: rootGetters.clientId,
          zhuboid,
          userid 
        }
        const id = encodeId(idsrc)
        let fromUser = {}
        if(Number(fromUin) === Number(zhuboid)) {
          fromUser = Object.assign({id: zhuboid}, zhuboInfo)
        } else {
          fromUser = Object.assign({id: userid}, userInfo)
        }
        const item = {
          id: msgId,
          status: 'succeed',
          type: msgTypt,
          content: text,
          sendTime: timestamp,
          toContactId: id,
          fromUser
        }
        ret.push(item)
			})
    commit('SET_HISTORMSG', ret)
    }
  },
  setUserStatus({state}, {status}){
    state.contactsData.forEach(u =>{ 
      const n = status.find(e => Number(e.uid)===Number(u.userInfo.uid))
      if(n) {
        u.userInfo.onlineStatus = n.status
      }
    })
  },
  newUserHosterLists({state}, item) {
    const { body } = item
    const id = encodeId(body)
    console.error('newChat:', body, id, state);
  },
  hosterImageLists({commit}, item) {
    const {images} = item
    let ret = []
    if(images) {
      ret = images.split(",")
    }
    commit('SET_HOSTIMAGE', ret)
  },
  sendChatMsgRsp({commit, rootGetters}, item){
    const {toUin, fromUin}  = item
    const tmp = {
      kefuid: rootGetters.clientId,
      zhuboid: fromUin,
      userid: toUin 
    }
    commit('SET_SENDMSGSTATUS', encodeId(tmp))
  },
  deleteSendMsgStatus({commit}, id){
    commit('DEL_SENDMSGATATUS', id)
  }
}

export default { 
  namespaced: true,
  state,
  mutations,
  actions
}
